import $ from "jquery";
import { resizeTimer } from "./resize-timer";
import { runIfElementExist } from "./run-if-element-exist";

function defineReviewDataItemPositionPreRow() {
  let item = $(".js-review-data-item"),
    devider = ".review__data__devider";

  $(item).each(function (i) {
    if ($(this).position().top > $(this).height()) {
      $(this)
        .addClass("is-second-row")
        .prev(devider)
        .addClass("prev-to-second-row-item");
    } else {
      $(this)
        .removeClass("is-second-row")
        .prev(devider)
        .removeClass("prev-to-second-row-item");
    }
  });
}

$(".js-reviews-btn:not(.active)").on("click", function () {
  setTimeout(() => {
    defineReviewDataItemPositionPreRow();
  }, 0);
});

runIfElementExist(defineReviewDataItemPositionPreRow, ".review");

resizeTimer(defineReviewDataItemPositionPreRow, ".review", 250);
