import $ from "jquery";

$(document).on("click", "*[data-drop-position]", function (event) {
  var wS = $(window).scrollTop(),
    wH = $(window).height(),
    wW = $(window).width(),
    target = $(this).attr("data-drop"),
    item = $("#" + target),
    headerHeight = $(".main-header__sticky-wrapper").height(),
    iHeight = item.outerHeight(),
    iWidth = item.outerWidth(),
    eHeight = $(this).outerHeight(),
    eCoord = $(this).offset(),
    eTop = eCoord["top"],
    eLeft = eCoord["left"],
    ePosTop = eTop - wS,
    eWidth = $(this).outerWidth(),
    hOffset = 0,
    additionalOffset = 16;

  /**********VERTICAL-POSITION-DEFINE*************/
  /************drop-down***********/
  if (wH - ePosTop > iHeight) {
    item.addClass("under").removeClass("above");
  } else if (wH - ePosTop <= iHeight && ePosTop - headerHeight > iHeight) {
    /************drop-up***********/
    item.addClass("above").removeClass("under");
  } else if (wH - ePosTop <= iHeight && ePosTop < iHeight) {
    /***********drop-neutral*****************/
    item.addClass("under").removeClass("above");
  }
  /**************HORIZONTAL-POSITION**************/

  if (wW - eLeft >= iWidth + additionalOffset) {
    item.addClass("right").removeClass("left neutral");
  } else if (
    wW - eLeft < iWidth + additionalOffset &&
    eLeft + iWidth + additionalOffset >= wW &&
    eLeft + eWidth < iWidth + additionalOffset
  ) {
    item
      .removeClass("left right")
      .addClass("neutral")
      .css("right", -(wW - (eLeft + eWidth + hOffset)) + "px");
  } else if (
    wW - eLeft < iWidth + additionalOffset &&
    eLeft + iWidth + additionalOffset >= wW &&
    eLeft + eWidth + additionalOffset >= iWidth + additionalOffset
  ) {
    item.addClass("left").removeClass("right neutral");
  }
});
