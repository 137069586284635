import $ from "jquery";
import "../plugins/slick.min.js";

(function () {
  const howItWorksSlider = () => {
    const sliderElement = $(".js-how-it-works-slider");
    if (window.matchMedia("(min-width: 768px)").matches) {
      if (sliderElement.hasClass("slick-initialized")) {
        sliderElement.slick("unslick");
      }
    } else {
      if (sliderElement.hasClass("slick-initialized")) {
        return;
      } else {
        sliderElement.slick({
          arrows: true,
          appendArrows: false,
          dots: true,
          appendDots: ".js-how-it-work-slider-dots",
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: false,
          infinite: false,
          draggable: true,
          swipe: true,
          touchMove: true,
          adaptiveHeight: true
        });
      }
    }
  };

  $(function () {
    howItWorksSlider();
  });

  $(window).on("orientationchange", function () {
    howItWorksSlider();
  });

  var resizeTimer;

  $(window).on("resize", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      howItWorksSlider();
    }, 250);
  });
})();
