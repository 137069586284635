import $ from "jquery";

(function () {
  function moveElements(overline, title, text, destination) {
    let arr = [];
    arr.push(overline, title, text);
    arr.forEach(function (el) {
      $(el).appendTo(destination);
    });
  }
  function pasteElementForMove() {
    let desktopWrap = $(".js-get-ready-info-desktop"),
      mobileWrap = $(".js-get-ready-info-mobile"),
      overline = $(".js-get-ready-overline"),
      title = $(".js-get-ready-title"),
      text = $(".js-get-ready-text");
    if (desktopWrap.length) {
      if (window.matchMedia("(min-width: 768px)").matches) {
        moveElements(overline, title, false, desktopWrap);
        moveElements(false, false, text, mobileWrap);
      }
      if (window.matchMedia("(min-width: 1024px)").matches) {
        moveElements(false, false, text, desktopWrap);
      }
      if (window.matchMedia("(max-width: 767px)").matches) {
        moveElements(overline, title, text, mobileWrap);
      }
    }
  }

  $(function () {
    pasteElementForMove();
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      pasteElementForMove();
    }, 100);
  });
})();
