import $ from "jquery";

$(document).on("click", "*[data-toggle]", function (event) {
  event.stopPropagation();
  let arr = $(this).attr("data-toggle"),
    target = arr.split(" "),
    toggleIndicator = true;
  if (toggleIndicator == true) {
    toggleIndicator = false;

    for (var i = 0; i < target.length; i++) {
      if (!$("#" + target[i]).hasClass("active")) {
        $("#" + target[i]).addClass("active");
        $(this).addClass("active");
        setTimeout(function () {
          toggleIndicator = true;
        }, 250);
      } else {
        $("#" + target[i]).removeClass("active");
        $(this).removeClass("active");
        setTimeout(function () {
          toggleIndicator = true;
        }, 250);
      }
    }
  }
});
