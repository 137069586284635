import $ from "jquery";

(function () {
  const mainSearchForm = $("#main-search-form");
  const inputSearchLocation = $("#search-location");
  const inputSearchLocationPlaceholder = $("#search-location-field-placeholder");

  if (inputSearchLocation?.length && inputSearchLocation.val().length !== 0) {
    inputSearchLocationPlaceholder.text(inputSearchLocation.val());
  }

  function clearField(field) {
    field.val("");
  }

  function setEmptyStateClasses(field) {
    if (field.val().length == 0) {
      field.closest(".field-input").removeClass("filled");
    }
  }

  $(document).on("input", ".js-search-location", function (e) {
    if ($(this).val().length == 0) {
      inputSearchLocationPlaceholder.text("");
    }
  });

  $(document).on("blur", ".js-search-location", function () {
    const inputSearchLocationPlaceholderText = inputSearchLocationPlaceholder.text();

    if (!inputSearchLocationPlaceholderText) {
      clearField($(this))
      setEmptyStateClasses($(this))
    } else {
      inputSearchLocation.val(inputSearchLocationPlaceholderText)
    }
  });

  $(document).on("keydown", ".js-search-location", function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();

      const inputSearchLocationPlaceholderText = inputSearchLocationPlaceholder.text();

      if (inputSearchLocationPlaceholderText) {
        inputSearchLocation.val(inputSearchLocationPlaceholderText)
      } else {
        inputSearchLocation.val("")
      }

      mainSearchForm.submit();
    }
  });
})();
