import $ from "jquery";

const getScrollbarWidth = () => {
  let scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.setProperty("--sw", scrollBarWidth + "px");
};

$(document).ready(function () {
  getScrollbarWidth();
});

$(window).on("resize orientationchange", function () {
  getScrollbarWidth();
});
