import $ from "jquery";

$(document).on("click", ".js-tab-location:not(.disabled)", function () {
  if ($(this).hasClass( "disabled" )) {
    return false
  }
  const locationDeliveryOptionsItems = $(".js-tab-location-item");
  locationDeliveryOptionsItems.removeClass("active");

  const dataFilter = $(this).attr("data-filter")
  const locationDeliveryOptionsItem = $(".js-tab-location-item[data-filter='"+ dataFilter +"']");

  locationDeliveryOptionsItem.addClass("active")


});

$(document).ready(function () {
  const SHOW_ITEM_COUNT = 4
  const locationDeliveryOptionsLists = $(".js-location-delivery-options-list");
  locationDeliveryOptionsLists.each(function (i) {
    const locationDeliveryOptionsListItems = $(this).find(".location-delivery-options-list__item")
    locationDeliveryOptionsListItems.each(function (i) {
      if (i < SHOW_ITEM_COUNT) {
        $(this).addClass("active");
      }
    });
  });

  const showBtn = $(".js-location-delivery-options-show-btn");
  showBtn.each(function (i) {
    const showBtnParent = $(this).closest(".location-delivery-options__list-container")
    const locationDeliveryOptionsListItems = $(showBtnParent).find(".location-delivery-options-list__item")
    if (locationDeliveryOptionsListItems.length <= SHOW_ITEM_COUNT) {
      $(this).hide();
    } else {
      $(this).show();
    }
  });
  
  $(showBtn).on("click", function () {
    $(this).toggleClass("show")
    const container = $(this).parent();
    const containerItems = $(container).find(".location-delivery-options-list__item");
    $(containerItems).removeClass("active")

    if ($(this).hasClass("show")) {
      $(containerItems).addClass("active")
    } else {
      containerItems.each(function (i) {
        if (i < SHOW_ITEM_COUNT) {
          $(this).addClass("active");
        }
      });
    }
  });
});