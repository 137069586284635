import $ from "jquery";

function myFavoritesPageStateDefine() {
  let card = $(".js-rv-card"),
    row = $(".js-rv-card-row"),
    emptyBox = $(".js-my-favorite-empty");
  if (!card.length) {
    row.remove();
    emptyBox.addClass("active");
  }
}

$(document).on("click", ".js-my-favorite-icon-btn", function () {
  myFavoritesPageStateDefine();
  return false
});

$(function () {
  myFavoritesPageStateDefine();
});
