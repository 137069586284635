import $ from "jquery";

$(document).on("click", ".js-tab", function () {
  if ($(this).hasClass("active")) {
    if ($(this).hasClass("js-tab-self")) {
      $(this).removeClass("active");
      $(document).trigger("tabs-turn-off", [$(this)]);
    }
    // return
    // $(document).trigger("tabs-switched", [$(this)]);
  } else {
    if ($(this).hasClass( "disabled" )) {
      return false
    }
    $(this).parents(".js-tab-parent").find(".js-tab").removeClass("active");
    $(this).addClass("active");
  }

  $(document).trigger("tabs-switched", [$(this)]);
});

$(document).on("click touch", "*[data-tab]", function () {
  var target = $(this).attr("data-tab");
  $(this).addClass("active").siblings(".js-tab").removeClass("active");
  $("#" + target)
    .addClass("active")
    .siblings(".js-tab-section")
    .removeClass("active");
});
