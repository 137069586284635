import $ from "jquery";

(function () {
  let field = $(".js-tabs-rv-class-select-value"),
    tab = $(".js-tab-rv-class.active");
  field.empty();
  tab.clone(true).appendTo(field);

  function switchToGetYouStartedSelectValue(button) {
    field.empty();
    button.clone(true).appendTo(field);
  }

  $(document).on("click", ".js-tab-rv-class", function () {
    switchToGetYouStartedSelectValue($(this));
  });
})();
