import $ from "jquery";

(function () {
  function countSymbolsInTheField() {
    let ele = $(".js-symbols-count");
    ele.each(function () {
      let target = $(this).data("target"),
        numbs = $(this).val().length,
        max = $(this).data("max");

      $(`#${target}`)
        .find(".js-symbols-max")
        .html(`&nbsp;&nbsp;${max}&nbsp;&nbsp;`);

      $(`#${target}`).find(".js-symbols-number").html(`${numbs}&nbsp;&nbsp;`);

      if (numbs > max) {
        $(`#${target}`).addClass("error");
      } else {
        $(`#${target}`).removeClass("error");
      }
    });
  }

  $(function () {
    countSymbolsInTheField();
  });
  $(document).on("input", ".js-symbols-count", function () {
    countSymbolsInTheField();
  });
})();
