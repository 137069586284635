import $ from "jquery";

$(function () {
  const modalKey = "cwrr:modal-notification-app";

  if (!localStorage.getItem(modalKey)) {
    $("#mdl-notification-app").addClass("active");
    localStorage.setItem(modalKey, "shown");
    $("body").addClass("overlay-open");
  }

  $("#mdl-notification-app-close").on("click", function() {
    $("#mdl-notification-app").removeClass("active");
    $("body").removeClass("overlay-open");
  });
})
