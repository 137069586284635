import $ from "jquery";
import "../plugins/slick.min.js";

(function () {
  const heroHomeSliderOptions = {
    arrows: true,
    appendArrows: ".hero-home__slider-arrows",
    appendDots: ".hero-home__slider-dots",
    dots: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    draggable: true,
    swipe: true,
    touchMove: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          draggable: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };
  $(".js-hero-home-slider").slick(heroHomeSliderOptions);

  const showHideDots = () => {
    setTimeout(() => {
      if ($(".hero-home__slider-arrows").find(".slick-arrow").length) {
        $(".hero-home__slider-dots .slick-dots").addClass("active");
      } else {
        $(".hero-home__slider-dots .slick-dots").removeClass("active");
      }
    }, 100);
  };

  if ($(".js-hero-home-slider").length) {
    $(function () {
      showHideDots();
    });

    $(window).on("resize orientationchange", function () {
      showHideDots();
    });
  }
})();
