import $ from "jquery";
import { runIfElementExist } from "./run-if-element-exist";

import { resizeTimer } from "./resize-timer";

function setChatPoistion() {
  let bottomLine = $("body > .bottom-line"),
    blHeight = bottomLine.outerHeight();
  if (bottomLine.length) {
    if (bottomLine.css("display") != "none") {
      document.documentElement.style.setProperty(
        "--cwrr-bottom-bar-height",
        blHeight + "px"
      );
    }
  } else {
    document.documentElement.style.setProperty(
      "--cwrr-bottom-bar-height",
      0 + "px"
    );
  }
}

runIfElementExist(setChatPoistion, ".bottom-line");
resizeTimer(setChatPoistion, ".bottom-line", 250);

/* for first release */
function setChatPoistionFirstRelease() {
  let bottomLine = $("body > .bottom-line"),
    blHeight = bottomLine.outerHeight(),
    bookingBox = $(".js-first-release-bottom-line"),
    bookingBoxHeight = bookingBox.outerHeight();

  if (bookingBox.length && !bottomLine.length) {
    if (window.matchMedia("(max-width: 1023px)").matches) {
      document.documentElement.style.setProperty(
        "--cwrr-bottom-bar-height",
        bookingBoxHeight + "px"
      );
    } else {
      document.documentElement.style.setProperty(
        "--cwrr-bottom-bar-height",
        0 + "px"
      );
    }
  } else if (bookingBox && bottomLine.length) {
    if (bottomLine.css("display") != "none") {
      document.documentElement.style.setProperty(
        "--cwrr-bottom-bar-height",
        blHeight + "px"
      );
    }
  }
}

runIfElementExist(setChatPoistionFirstRelease, ".js-first-release-bottom-line");
resizeTimer(setChatPoistionFirstRelease, ".js-first-release-bottom-line", 250);
