import $ from "jquery";

$(document).on("click", "*[data-slide]", function (event) {
  event.stopPropagation();
  if (slideToggleIndicator == true) {
    slideToggleIndicator = false;
    var target = $(this).attr("data-slide");
    if (!$("#" + target).hasClass("active")) {
      $("#" + target).slideDown(250, function () {
        $(this).addClass("active");
        slideToggleIndicator = true;
      });
    } else {
      $("#" + target)
        .removeClass("active")
        .slideUp(250, function () {
          slideToggleIndicator = true;
        });
    }
  }
  slideToggleIndicator;
});
