import $ from "jquery";

function resizeTimer(script, identifier, time) {
  /* 
    script - function to run
    identifire - the element wich must be on page to run function
    time - timeout
     */
  let resizeTimer;
  $(window).on("resize orientationchange", function (e) {
    if ($(identifier).length) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        script();
      }, time);
    }
  });
}

export { resizeTimer };
