import $ from "jquery";

(function () {
  function changeTopNavPosition() {
    let projects = $(".top-nav-projects"),
      topNav = $(".top-nav"),
      topNavWhiteLabel = $(".top-nav.white-label-header"),
      body = $("body"),
      wS = $(window).scrollTop();
      if (topNavWhiteLabel.length > 0) {
        body.addClass("body-top-nav-fixed-white-label");
      }
    if (wS >= projects.outerHeight() && topNavWhiteLabel.length === 0) {
      topNav.addClass("top-nav--fixed");
      body.addClass("body-top-nav-fixed");
    } else {
      topNav.removeClass("top-nav--fixed");
      body.removeClass("body-top-nav-fixed");
    }
  }
  $(function () {
    changeTopNavPosition();
  });

  $(window).on("scroll", function () {
    changeTopNavPosition();
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      changeTopNavPosition();
    }, 250);
  });
})();
