import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete';

const Autocomplete = $.ui.autocomplete;

$.widget("custom.basicautocomplete", Autocomplete, {
  options: {
    position: {
      my: 'left top+4'
    },
  },
  _renderMenu(ul, items) {
    Autocomplete.prototype._renderMenu.call(this, ul, items);

    ul.addClass('field-autocomplete-menu');
  }
});

if (!window.CWRR) {
  window.CWRR = {};
}

window.CWRR.Autocomplete = {
  init(node, options) {
    return $(node).basicautocomplete(options);
  },
  destroy(node) {
    return $(node).basicautocomplete("destroy");
  },
  setOption(node, option, value) {
    return $(node).basicautocomplete("option", option, value);
  }
};
