import $ from "jquery";

$(document).on("click", ".js-gs-insurance-accordion-head", function () {
  var parent = $(".js-gs-insurance-accordion"),
    body = $(".js-gs-insurance-accordion-body"),
    btn = $(".js-gs-insurance-accordion-head");

  $(this).addClass("active").siblings(body).slideToggle();

  $(this).closest(parent).siblings(parent).removeClass("active");

  if (window.matchMedia("(max-width: 1023px)").matches) {
    $(this).closest(parent).toggleClass("active");
  } else {
    $(this).closest(parent).addClass("active").find(btn).addClass("active");
    $(this).closest(parent).find(body).slideDown();
  }

  $(this)
    .closest(parent)
    .siblings()
    .find(btn)
    .not($(this))
    .each(function (i) {
      $(this).removeClass("active").siblings(body).slideUp();
    });
});
