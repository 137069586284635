import $ from "jquery";
import "jquery-ui/ui/widgets/selectmenu";
import "jquery-mask-plugin";
import countryCodes from "./country-codes.json";

const orderedCountries = countryCodes
  .map(([label, phonePrefix, code]) => ({
    value: code.toLowerCase(),
    label,
    phonePrefix: `+${phonePrefix}`
  }))
  .filter(Boolean);

$.widget("custom.countryselectmenu", $.ui.selectmenu, {
  _resizeMenu() {
    const {
      menu,
      options: { getParentWidth }
    } = this;

    if (getParentWidth) {
      menu.outerWidth(getParentWidth());
    }
  },
  _drawButton() {
    $.ui.selectmenu.prototype._drawButton.call(this);

    this.button
      .addClass("field-input__country-select-button")
      .removeClass("ui-button");
  },
  _renderButtonItem: function (item) {
    const buttonItem = $("<span>", {
      class: "field-input__country-select-content"
    });

    buttonItem.prepend(
      $("<span>", {
        class: `country-flag-icon country-flag-icon--${item.value}`
      })
    );

    return buttonItem;
  },
  _renderItem(ul, item) {
    const $li = $("<li>", { class: "field-input-country-menu__item" });
    const $wrapper = $("<div>", {
      class: "field-input-country-menu__item-wrapper"
    });

    if (item.disabled) {
      $li.addClass("ui-state-disabled");
    }

    $wrapper.prepend(
      $("<span>", {
        class: `country-flag-icon country-flag-icon--${item.value}`
      })
    );
    $wrapper.append(
      $("<span>", {
        class: "field-input-country-menu__item-label",
        text: item.label
      })
    );
    $wrapper.append(
      $("<span>", {
        class: 'field-input-country-menu__phone-prefix',
        text: $(item.element).data('phonePrefix')
      })
    );

    $li.append($wrapper);

    // TODO: it might we can control the order of the items somehow
    $li.appendTo(ul);

    return $li;
  },
  _renderMenu(ul, item) {
    $.ui.selectmenu.prototype._renderMenu.call(this, ul, item);

    $(ul).addClass("field-input-country-menu");
  }
});

function createKeyPressHandler(phonePrefix) {
  return (cep, event, currentField) => {
    if (cep.indexOf(phonePrefix) !== 0) {
      $(currentField).val(phonePrefix);
    }
  };
}

function getCountryByValue(value) {
  const matchedCountries = orderedCountries
    .filter(({ phonePrefix }) => value.indexOf(phonePrefix) === 0)
    .reverse();

  if (matchedCountries.length > 0) {
    return matchedCountries[0];
  }

  return orderedCountries.find(({ value }) => value === "us");
}

$.fn.phoneMask = function () {
  return this.each((index, element) => {
    const $root = $(element).parent();
    const $prefixSelector = $("<select>").prependTo($root);
    const $input = $root.find("input");

    const options = orderedCountries
      .map(({ value, label, phonePrefix }) => {
        return `<option value="${value}" data-phone-prefix="${phonePrefix}">${label}</option>`;
      })
      .join("");

    $prefixSelector.html(options);
    $prefixSelector.countryselectmenu({
      position: {
        my: "left-12 top+18"
      },
      getParentWidth() {
        return $input.outerWidth();
      },
      select(e, ui) {
        const phonePrefix = ui.item.element.data("phonePrefix");

        if (phonePrefix === "+1") {
          $input.mask("+1-000-000-0000", {
            placeholder: "+1-___-___-____",
            onKeyPress: createKeyPressHandler(phonePrefix)
          });
        } else {
          $input.mask(phonePrefix + "00000000000000", {
            placeholder: phonePrefix,
            onKeyPress: createKeyPressHandler(phonePrefix)
          });
        }

        $input
          .val(phonePrefix)
          .focus()
          .trigger("change");
      }
    });

    const val = $input.val();
    const countryInfo = getCountryByValue(val);

    $prefixSelector.val(countryInfo.value).countryselectmenu("refresh");

    if (countryInfo.value === 'us') {
      // US phone number
      $input.mask("+1-000-000-0000", {
        placeholder: "+1-___-___-____",
        onKeyPress: createKeyPressHandler("+1")
      });
    } else {
      // Other countries
      const { phonePrefix } = countryInfo;

      $input.mask(phonePrefix + "00000000000000", {
        placeholder: phonePrefix,
        onKeyPress: createKeyPressHandler(phonePrefix)
      });
    }
  });
};

// $(".js-input-phone").phoneMask();

if (!window.CWRR) {
  window.CWRR = {};
}

window.CWRR.FieldPhone = {
  init: node => $(node).phoneMask(),
  destroy: node => $(node).phoneMask("destroy")
};
