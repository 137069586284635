import $ from "jquery";

$(document).on("focus", ".js-datepicker", function () {
  let datepicker = $(".ui-datepicker"),
    arr = [];
  datepicker.each(function () {
    arr.push($(this));
  });
  $(this).closest(".js-datepicker-container").append(arr);
});
