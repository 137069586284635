import $ from "jquery";

/**
 * @param {Number} total
 */
function updateTotal(total) {
  $(".js-mobile-menu-item-chat-badge")
    .text(`${total}`)
    .toggleClass('pill--hidden', total < 1);

  $('.js-button-mobile-menu')
    .toggleClass('top-nav__button-mobile--notification', total >= 1);

  $('.js-top-nav-chat-button')
    .toggleClass('top-nav-chat__btn--notification', total >= 1);
}

function convertToInteger(value, defaultValue) {
  const n = typeof value !== 'number' ? parseFloat(value) : value;

  return Number.isNaN(n) ? defaultValue : Math.round(n);
}

$(function () {
  const chatContainer = document.getElementById("chat-container");

  if (!chatContainer) {
    return;
  }

  const initialTotal = chatContainer.getAttribute('data-total-unread');

  updateTotal(convertToInteger(initialTotal, 0));

  chatContainer.addEventListener("set-total-unread", ({ detail: { total = 0 } }) => {
    if (typeof total === 'number') {
      updateTotal(total);
    }
  });

  $(".js-mobile-menu-item-chat, .js-top-nav-chat-button")
    .on("click", function (e) {
      e.preventDefault();
      chatContainer.dispatchEvent(new CustomEvent("toggle-chat"));
    });
});
