import $ from "jquery";
import "../plugins/slick.min.js";

(function () {
  let resizeTimer;

  function clpSliderDotsPosition(slick) {
    const image = slick.$list.find(".js-clp-slider-image");
    const imageMargin = parseInt(image.css("margin-top"), 10);
    const imageH = image.outerHeight();
    const dotsH = slick.$dots.outerHeight();
    const dotsTop = imageH + imageMargin - dotsH;

    if (!isNaN(dotsTop)) {
      slick.$dots.css({
        top: `${dotsTop}px`,
        bottom: "auto"
      });
    }
  }

  function clpSliderArrowsPosition(slick) {
    const hasArrows = slick.$nextArrow && slick.$prevArrow;
    const image = slick.$list.find(".js-clp-slider-image");
    const imageHalfH = Math.round(image.outerHeight() / 2);

    if (!isNaN(imageHalfH) && hasArrows) {
      slick.$nextArrow.css("top", `${imageHalfH}px`);
      slick.$prevArrow.css("top", `${imageHalfH}px`);
    }
  }

  const clpSliderDotsVisibility = (slick) => {
    if (slick.slideCount > 1) {
      slick.$dots.addClass("active");
    }
  };

  const clpSlider = ($el) => {
    const sliderElement = $(".js-clp-slider");

    sliderElement.each(function () {
      const $el = $(this);
      const $arrowContainer = $(this)
        .closest(".js-clp-slider-container")
        .find(".js-clp-slider-arrows");

      if (!$el.hasClass("slick-initialized")) {
        $el.slick({
          arrows: true,
          appendArrows: $arrowContainer,
          dots: true,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 1023,
              settings: {
                arrows: true
              }
            }
          ]
        });
      }

      const slick = $el.slick("getSlick");
      clpSliderDotsPosition(slick);
      clpSliderArrowsPosition(slick);
      clpSliderDotsVisibility(slick);

      const sliderDots = $el.find(".slick-dots");
    });
  };

  $(function () {
    if ($(".js-clp-slider").length) {
      clpSlider();

      $(window).on("resize orientationchange", function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          clpSlider();
        }, 150);
      });
    }
  });
})();
