import $ from "jquery";
import { showMainSearch, hideMainSearch } from "./main-search-show-hide";

(function () {
  function homeSearchPositionDefine() {
    let frontier = $(".js-hero-search-frontier");
    const topNavHomePageLink = $(".js-top-nav-logo");
    if (frontier.length) {
      let wS = $(window).scrollTop(),
        topNav = $(".top-nav__panel"),
        topNavH = topNav.outerHeight(),
        frontierTop = frontier.offset().top,
        search = $(".js-top-nav-search"),
        searchPh = $(".js-show-main-search-btn"),
        heroPlace = $(".js-hero-search-place"),
        heroPlaceTop = $(".js-hero-search-place").offset().top,
        heroPlaceH = $(".js-hero-search-place").outerHeight(),
        topNavPlace = $(".js-top-nav-search-space")
      // if (wS > frontierTop - topNavH) {
      if (wS > heroPlaceTop + (heroPlaceH - topNavH)) {
        if (!topNavPlace.find(search).length) {
          search.appendTo(topNavPlace);
          searchPh.addClass("active");
        }
        if (!topNavPlace.hasClass("filled-in")) {
          topNavPlace.addClass("filled-in");
          topNavHomePageLink.addClass("top-nav__homepage-link--search");
          heroPlace.removeClass("filled-in");
        }
        if (search.hasClass("datepicker-opened")) {
          showMainSearch();
        }
      } else {
        if (!heroPlace.find(search).length) {
          search.appendTo(heroPlace).removeClass("active").attr("style", "");
          searchPh.removeClass("active");
        }
        if (!heroPlace.hasClass("filled-in")) {
          topNavPlace.removeClass("filled-in");
          topNavHomePageLink.removeClass("top-nav__homepage-link--search");
          heroPlace.addClass("filled-in");
        }
      }
    } else {
      topNavHomePageLink.addClass("top-nav__homepage-link--search");
    }
  }
  $(function () {
    homeSearchPositionDefine();
  });
  $(window).on("scroll orientationchange", function () {
    homeSearchPositionDefine();
  });
})();
