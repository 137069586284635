import $ from "jquery";

$(document).on("click", ".js-rd-table-btn", function () {
  let wW = $(window).width(),
    btn = $(this),
    btnWidth = btn.outerWidth(),
    btnLeft = btn.offset().left,
    target = $(this).attr("data-drop"),
    elem = $("#" + target).find(".js-rd-table-tooltip-box"),
    elemWidth = elem.outerWidth();

  if (window.matchMedia("(min-width: 768px)").matches) {
    elem.attr("style", "");
  } else {
    if (btnLeft < elemWidth / 2 + 32) {
      elem.css({
        left: `-${btnLeft - 32}px`,
        transform: "translateX(0)"
      });
    }
  }
});
