import $ from "jquery";
import "../plugins/slick.min.js";

(function () {
  const trendingArticlesSlider = () => {
    const sliderElement = $(".js-trending-slider");
    sliderElement.on("destroy", function () {
      $(document).trigger("slick-stop", [$(this)]);
    });
    if (window.matchMedia("(min-width: 1024px)").matches) {
      if (sliderElement.hasClass("slick-initialized")) {
        sliderElement.slick("unslick");
      }
    } else {
      if (sliderElement.hasClass("slick-initialized")) {
        return;
      } else {
        sliderElement.slick({
          arrows: false,
          dots: true,
          appendDots: ".js-trending-dots",
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: false,
          infinite: true,
          draggable: true,
          swipe: true,
          touchMove: true,
          adaptiveHeight: true,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                appendArrows: ".js-trending-arrows"
              }
            }
          ]
        });
      }
    }
  };

  if ($(".js-trending-slider").length) {
    $(function () {
      trendingArticlesSlider();
    });

    var resizeTimer;

    $(window).on("resize orientationchange", function (e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        trendingArticlesSlider();
      }, 250);
    });
  }
})();
