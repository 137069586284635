import $ from "jquery";

(function () {
  const amenititesTransfer = () => {
    let mobileContainer = $(".js-amenities-mobile-container"),
      desktopContainer = $(".js-amenities-desktop-container"),
      transferBlock = $(".js-amenities-transfer-block");
    if (window.matchMedia("(min-width: 768px)").matches) {
      if (desktopContainer.find(transferBlock).length) {
        return;
      } else {
        transferBlock.appendTo(desktopContainer);
      }
    } else {
      if (mobileContainer.find(transferBlock).length) {
        return;
      } else {
        transferBlock.appendTo(mobileContainer);
      }
    }
  };
  $(document).ready(function () {
    amenititesTransfer();
  });
  $(window).on("resize orientationchange", function () {
    amenititesTransfer();
  });
})();
