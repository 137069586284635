import $ from "jquery";
import { overlayStart, overlayEnd } from "./actions/open-close.js";

const $body = document.querySelector("body");
let scrollPosition = 0;

$(document).on("touchstart", "input", function () {
  // overlayStart();
  // scrollPosition = window.pageYOffset;
  // $body.style.overflow = "hidden";
  // $body.style.position = "fixed";
  // $body.style.top = `-${scrollPosition}px`;
  // $body.style.width = "100%";
});
$(document).on("touchend", "input", function () {
  // overlayEnd();
  // $body.style.removeProperty("overflow");
  // $body.style.removeProperty("position");
  // $body.style.removeProperty("top");
  // $body.style.removeProperty("width");
  // window.scrollTo(0, scrollPosition);
});
