import $ from "jquery";

(function () {
  const calculateAmenitiesTotal = () => {
    let tile = $(".js-tile-filter");
    $(".js-tile-quont").html(tile.length);
  };
  const filterAmenitiesOnPublicVehicle = () => {
    let tabActive = $(".js-amenities-filter-tab.active"),
      filter = tabActive.data("filter"),
      tile = $(".js-tile-filter");
    tile.each(function () {
      if (filter == "all") {
        $(this).addClass("active");
      } else if ($(this).data("filter") == filter && filter != "all") {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  };
  $(document).ready(function () {
    if ($(".js-amenities-filter-tab").length) {
      filterAmenitiesOnPublicVehicle();
      calculateAmenitiesTotal();
    }
  });
  $(document).on("click", ".js-amenities-filter-tab", function () {
    filterAmenitiesOnPublicVehicle();
  });
})();
