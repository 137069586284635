import $ from "jquery";

$(document).on("click", ".js-booking-box-notification-mobile", function () {
  if (window.matchMedia("(max-width: 767px)").matches) {
    var arr = $(this).data("notification-mobile");
    var target = arr.split(" ");
    for (var i = 0; i < target.length; i++) {
      $(`#${target[i]}`).addClass("active");
    }

    var arrClose = $(this).data("notification-mobile-close");
    var targetClose = arrClose.split(" ");
    for (var i = 0; i < targetClose.length; i++) {
      $(`#${targetClose[i]}`).removeClass("active");
    }
  } else {
    return;
  }
});
