import $ from "jquery";

function setAdditionalBottomOffsetOnClpPages() {
  let video = $(".video"),
    imageWidthText = $(".image-with-text"),
    sellingPoint = $(".selling-point:not(.selling-point--how-it-works)"),
    clpImage = $(".clp-image"),
    textClp = $(".text-clp"),
    clpHero = $(".clp-hero"),
    vehicles = $(".vehicles"),
    accordion = $(".accordion"),
    bodyChild = $("body > *"),
    footer = $("footer"),
    arr = [];
  arr.push(
    video,
    imageWidthText,
    sellingPoint,
    clpImage,
    textClp,
    clpHero,
    vehicles,
    accordion
  );
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].length) {
      bodyChild.each(function (i) {
        if ($(this).index() == footer.index() - 1) {
          $(this).addClass("clp-last");
        }
      });
    }
  }
}

$(function () {
  setAdditionalBottomOffsetOnClpPages();
});
