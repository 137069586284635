import $ from "jquery";
$(document).on("click", ".js-zindex", function () {
  $(this)
    .closest(".js-zindex-parent")
    .find(".js-zindex")
    .each(function () {
      $(this).css("z-index", "9");
    });
  $(this).css("z-index", "10");
});
