import $ from "jquery";
import { tabsUnderlineTranslateX } from "../tabs-underline.js";

function overlayStart() {
  var wS = window.pageYOffset;
  $("body").addClass("overlay-open");
  $("body").css("top", -wS + "px");
}

function overlayEnd() {
  var wS = Math.abs(parseInt($("body").css("top")));
  $("body").css("top", "auto");
  $("body").removeClass("overlay-open");
  $(window).scrollTop(wS);
}

$(document).on("click", "*[data-open]", function () {
  var arr = $(this).attr("data-open");
  var target = arr.split(" ");
  for (var i = 0; i < target.length; i++) {
    $("#" + target[i]).addClass("active");
    if (target[i] === "mdl-list-your-rv") {
      $("body").addClass("overlay-open");
    }
    if ($(target[i]).hasClass("mdl-wrap--full-screen")) {
      overlayStart();
    }
    if ($("#" + target[i]).find(".tabs-underline").length) {
      tabsUnderlineTranslateX();
    }
  }
});
$(document).on("click", '*[data-open*="mdl"]', function () {
  if (!$(this).hasClass("js-mdl-close")) {
    var target = $(this).attr("data-open");
    if ($("#" + target).hasClass("mdl-wrap--full-screen")) {
      overlayStart();
    } else {
      if ($(".touchevents").length) {
        overlayStart();
      }
    }
  }
});

$(document).on("click", "*[data-close]", function () {
  var arr = $(this).attr("data-close");
  var target = arr.split(" ");
  for (var i = 0; i < target.length; i++) {
    $("#" + target[i]).removeClass("active");
  }
  $(document).trigger("somethingClosed", [$(this)]);
});

$(document).on("click", ".js-mdl-close", function () {
  const dataCloseValue = $(this).attr("data-close");
  if (dataCloseValue === "mdl-list-your-rv-close" || dataCloseValue === "mdl-notification-app-close") {
    $("body").removeClass("overlay-open");
  }
  if ($(".mdl-wrap.active").length > 1) {
    if ($(this).hasClass("mdl-wrap")) {
      $(this).removeClass("active");
    } else {
      $(this).closest(".mdl-wrap").removeClass("active");
    }
  } else {
    if ($(this).hasClass("mdl-wrap")) {
      $(this).removeClass("active");
    } else {
      $(this).closest(".mdl-wrap").removeClass("active");
    }
    if (
      $(".touchevents").length ||
      $(this).closest(".mdl-wrap").hasClass("mdl-wrap--full-screen")
    ) {
      overlayEnd();
    }
  }
});
$(document).on("click", ".mdl", function (event) {
  event.stopPropagation();
});

$(document).on("click", ".js-self-close", function () {
  $(this).removeClass("active");
});

$(document).on("click", "*[data-remove]", function () {
  var target = $(this).attr("data-remove");
  $("#" + target).remove();
});

export { overlayStart, overlayEnd };
