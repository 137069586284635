import $ from "jquery";
import { runIfElementExist } from "./run-if-element-exist";
import { resizeTimer } from "./resize-timer";

function setNumberOfAdditionalPhotos() {
  let elem = $(".js-additional-photos"),
    wraper = $(".review__cars");
  elem.each(function () {
    let number = $(this).attr("data-photos-number"),
      previews = $(this)
        .closest(".review__cars")
        .find(".col:not(.hidden-flex)"),
      numberElem = $(this).find(".js-review-img-num"),
      numberText;
    if (window.matchMedia(`(max-width: 767px)`).matches) {
      if (number > 3 && previews.length >= 3) {
        // elem.closest(".hidden-flex").addClass("active");
        numberText = number - 3;
      } else {
        // elem.closest(".hidden-flex").removeClass("active");
      }
    } else if (
      window.matchMedia(`(min-width: 768px)`).matches &&
      window.matchMedia(`(max-width: 1023px)`).matches
    ) {
      if (number > 4 && previews.length >= 4) {
        // elem.closest(".hidden-flex").addClass("active");
        numberText = number - 4;
      } else {
        // elem.closest(".hidden-flex").removeClass("active");
      }
    } else if (window.matchMedia(`(min-width: 1024px)`).matches) {
      if (number > 5 && previews.length >= 5) {
        // elem.closest(".hidden-flex").addClass("active");
        numberText = number - 5;
      } else {
        // elem.closest(".hidden-flex").removeClass("active");
      }
    }
    numberElem.text(numberText);
  });
  wraper.each(function () {
    let previews = $(this).find(".col:not(.hidden-flex)"),
      previewsLength = previews.length,
      number = $(this).find(".js-additional-photos").attr("data-photos-number");
    if (window.matchMedia(`(max-width: 767px)`).matches) {
      if (number > 3 && previewsLength > 3) {
        $(this).find(".hidden-flex").addClass("active");
      } else {
        $(this).find(".hidden-flex").removeClass("active");
      }
    } else if (
      window.matchMedia(`(min-width: 768px)`).matches &&
      window.matchMedia(`(max-width: 1023px)`).matches
    ) {
      if (number > 4 && previewsLength > 4) {
        $(this).find(".hidden-flex").addClass("active");
      } else {
        $(this).find(".hidden-flex").removeClass("active");
      }
    } else if (window.matchMedia(`(min-width: 1024px)`).matches) {
      if (number > 5 && number - previewsLength > 0) {
        $(this).find(".hidden-flex").addClass("active");
      } else {
        $(this).find(".hidden-flex").removeClass("active");
      }
    }
  });
}

runIfElementExist(setNumberOfAdditionalPhotos, ".js-additional-photos");

resizeTimer(setNumberOfAdditionalPhotos, ".js-additional-photos", 250);
