import $ from "jquery";
// import { overlayStart, overlayEnd } from "./actions/open-close.js";

(function () {
  let wS;
  $(document).on("click", ".js-button-mobile-menu", function () {
    wS = window.pageYOffset;
    $("body").addClass("overlay-open");
    $("body").css("top", -wS + "px");

    // setMobileMenuHeight();
  });

  $(document).on("click", ".js-button-mobile-menu-close", function () {
    wS = Math.abs(parseInt($("body").css("top")));
    $("body").css("top", "auto");
    $("body").removeClass("overlay-open");
    $(window).scrollTop(wS);
  });
})();
