import $ from "jquery";

(function () {
  function setFooterStateIfBottomLine() {
    let bottomLine = $(".bottom-line"),
      footer = $(".footer");
    if (bottomLine.length) {
      footer.addClass("footer--bottom-line");
    } else {
      footer.removeClass("footer--bottom-line");
    }
  }
  function setFooterStateIfBookingBox() {
    let bookingBox = $(".booking-box"),
      footer = $(".footer");
    if (bookingBox.length) {
      if (window.matchMedia("(max-width: 1023px)").matches) {
        footer.css({
          "margin-bottom": `${bookingBox.outerHeight()}px`
        });
      } else {
        footer.attr("style", "");
      }
    }
  }

  $(function () {
    setFooterStateIfBottomLine();
    setFooterStateIfBookingBox();
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      setFooterStateIfBookingBox();
    }, 250);
  });
})();
