import $ from "jquery";
import { resizeTimer } from "./resize-timer";
import { runIfElementExist } from "./run-if-element-exist";

/* 
if you want to transfer element/(set of elements)
from one block to another at a certain breakpoint
just add the attribute "data-transfer" to the element with some parameters separated by a space:
data-transfer="wrap1 insertType1 wrap2 insertType2 breakpoint"
"wrap1"/"insertType1" - where/how at media breakpoint >= "breakpoint"
"wrap2"/"insertType2" - where/how at media breakpoint < "breakpoint"
insertType can take 2 values:
 append - if it needes to add at the end of block
 prepend - if it needes to add at the begining of block
*/

function transferElements() {
  const $elem = $("*[data-transfer]");

  $elem.each(function () {
    let $t = $(this),
      $arr = $t.attr("data-transfer").split(" "),
      $wrap1 = $arr[0],
      $insertType1 = $arr[1],
      $wrap2 = $arr[2],
      $insertType2 = $arr[3],
      breakpoint = $arr[4];

    const transfer = ($wrap, $insertType) => {
      if ($insertType == "append") {
        $t.each(function () {
          if (!$(`#${$wrap}`).find($(this)).length) {
            $(`#${$wrap}`).append($(this));
          }
        });
      } else if ($insertType == "prepend") {
        $($t.get().reverse()).each(function () {
          if (!$(`#${$wrap}`).find($(this)).length) {
            $(`#${$wrap}`).prepend($(this));
          }
        });
      }
    };

    if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
      transfer($wrap1, $insertType1);
    } else {
      transfer($wrap2, $insertType2);
    }
  });
}

runIfElementExist(transferElements, "*[data-transfer]");

resizeTimer(transferElements, "*[data-transfer]", 250);
