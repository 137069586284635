import $ from "jquery";

let mainSearch = $(".js-top-nav-search"),
  topNavContainer = $(".js-top-nav-container");

function showMainSearch() {
  topNavContainer.addClass("top-nav__container--mobile-search");
  mainSearch.fadeIn(150, function () {});
}
function hideMainSearch() {
  mainSearch.fadeOut(150, function () {
    topNavContainer.removeClass("top-nav__container--mobile-search");
  });
}

$(document).on("click", ".js-show-main-search-btn", function () {
  showMainSearch();
});

$(document).on("click", ".js-hide-main-search-btn", function () {
  hideMainSearch();
});

export { showMainSearch, hideMainSearch };
