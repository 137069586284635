import $ from "jquery";

const collapsingTextUniversal = () => {
  let item = $(".js-collapsing-txt-inner"),
    maxHeight;

  if (item.closest(".js-collapsing-txt").hasClass("profile-info__about")) {
    if (window.matchMedia("(min-width: 0px) and (max-width: 767px)").matches) {
      maxHeight = 147;
    } else if (
      window.matchMedia("(min-width: 768px) and (max-width: 1023px)").matches
    ) {
      maxHeight = 72;
    } else if (window.matchMedia("(min-width: 1024px)").matches) {
      maxHeight = 168;
    }
  }

  item.each(function () {
    if ($(this).height() <= maxHeight) {
      $(this).closest(".js-collapsing-txt").addClass("no-expand");
    } else {
      $(this).closest(".js-collapsing-txt").removeClass("no-expand");
    }
  });
};

$(function () {
  collapsingTextUniversal();
});

$(window).on("resize orientationchange", function () {
  collapsingTextUniversal();
});
