// Сначала получаем высоту окна просмотра
// и умножаем ее на 1%
let vh = window.innerHeight * 0.01;

// Затем устанавливаем значение свойства --vh
// для корневого элемента
document.documentElement.style.setProperty("--vh", vh + "px");

// слушаем событие resize
window.addEventListener("resize", function () {
  // получаем текущее значение высоты
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");
});

// another script
function getViewport() {
  var viewPortWidth;
  var viewPortHeight;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth != "undefined") {
    (viewPortWidth = window.innerWidth), (viewPortHeight = window.innerHeight);
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (
    typeof document.documentElement != "undefined" &&
    typeof document.documentElement.clientWidth != "undefined" &&
    document.documentElement.clientWidth != 0
  ) {
    (viewPortWidth = document.documentElement.clientWidth),
      (viewPortHeight = document.documentElement.clientHeight);
  }

  // older versions of IE
  else {
    (viewPortWidth = document.getElementsByTagName("body")[0].clientWidth),
      (viewPortHeight = document.getElementsByTagName("body")[0].clientHeight);
  }
  return [viewPortWidth, viewPortHeight];
}
