import $ from "jquery";

$(document).on("click", ".js-video-play-btn", function () {
  let video = $(this).closest(".video").find("video").get(0);
  video.play();
  $(this).closest(".video").find("video").attr("controls", true);
});

$("video").on("pause stop", function () {
  $(this).closest(".video").removeClass("is-played");
  $(this).removeAttr("controls");
});

$("video").on("play", function () {
  $(this).closest(".video").addClass("is-played");
  $(this).closest(".video").find("video").attr("controls", true);
  $("video")
    .not($(this))
    .each(function () {
      $(this).get(0).pause();
      $(this).closest(".video").removeClass("is-played");
      $(this).removeAttr("controls");
    });
});
