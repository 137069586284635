import $ from "jquery";
import "jquery-ui/ui/widgets/selectmenu";

const SelectMenu = $.ui.selectmenu;

$.widget("custom.basicselect", SelectMenu, {
  options: {
    classes: {
      "ui-selectmenu-button-open": "ui-corner-all",
      "ui-selectmenu-button-closed": "ui-corner-all"
    },
    position: {
      my: "left top+4",
      collision: "flipfit"
    }
  },
  _drawButton() {
    SelectMenu.prototype._drawButton.call(this);

    this.button.addClass("field-select-button").removeClass("ui-button");
  },
  _renderMenu(ul, items) {
    SelectMenu.prototype._renderMenu.call(this, ul, items);

    ul.addClass("field-select-menu");

    // We use optional data-menu-container-class-name attribute to customize dropdown menu container element
    const menuContainerClassName = this.element.data('menuContainerClassName');

    if (menuContainerClassName) {
      ul.parent().addClass(menuContainerClassName);
    }

    // We use optional data-menu-class-name attribute to customize dropdown menu element
    const menuClassName = this.element.data('menuClassName');

    if (menuClassName) {
      ul.addClass(menuClassName);
    }
  }
});

// common select

$(".js-basic-select").basicselect({
  select: function (event, ui) {
    if (ui.item.index !== 0 || (ui.item.index === 0 && ui.item.value !== "")) {
      $(this).closest(".field-input").addClass("filled");
    } else {
      $(this).closest(".field-input").removeClass("filled");
    }
  }
});

// select for top-nav-search
(function () {
  $(function () {
    const $selectSearch = $(".js-select-search");
    if ($selectSearch.length) {
      $selectSearch.selectmenu({
        classes: {
          "ui-selectmenu-button":
            "field-select-button field-select-button--search",
          "ui-selectmenu-menu": "field-select-menu field-select-menu--search",
          "ui-selectmenu-button-open": "ui-corner-all",
          "ui-selectmenu-button-closed": "ui-corner-all"
        },
        appendTo: ".field-input--search--sleeps",
        position: {
          my: "left top+8"
        },
        open: function (event, ui) {
          $(".field-select-menu--search .ui-menu-item:first-child").css(
            "display",
            "none"
          );
        },
        select: function (event, ui) {
          if (ui.item.index !== 0) {
            $(this).closest(".field-input").addClass("filled");
            if (window.matchMedia("(max-width: 767px)").matches) {
              $(this)
                .closest(".field-input")
                .find(".ui-selectmenu-text")
                .html("Sleeps " + ui.item.value);
            }
            // $(".js-main-search-ph-sleeps")
            //   .html(ui.item.value)
            //   .closest(".main-search-ph__item")
            //   .addClass("filled");
          } else {
            $(this).closest(".field-input").removeClass("filled");
          }
        }
      });

      const handleSelectMenuClose = () => {
        $selectSearch.selectmenu("close");
      };

      window.addEventListener("resize", handleSelectMenuClose);
      window.addEventListener("orientationchange", handleSelectMenuClose);
    }
  });
})();

if (!window.CWRR) {
  window.CWRR = {};
}

window.CWRR.Select = {
  init(node, options) {
    return $(node).basicselect(options);
  },
  destroy(node) {
    return $(node).basicselect("destroy");
  },
  setOptions(node, option, value) {
    return $(node).basicautocomplete("option", option, value);
  },
  close(node) {
    return $(node).basicselect("close");
  }
};
