import $ from "jquery";

function isAndroid() {
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  if (isAndroid) {
    $("body").addClass("android");
  }
}

function isMac() {
  var isMac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)
    ? true
    : false;
  if (isMac) {
    $("body").addClass("mac");
  }
}

$(function () {
  isAndroid();
  isMac();
});
