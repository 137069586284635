import $ from "jquery";
(function () {
  let currentFieldValue;

  function clearFieldByFocus(field) {
    field.val("");
  }
  function saveCurrentFieldValue(field) {
    currentFieldValue = field.val();
    return currentFieldValue;
  }
  function insertSavedCurrentFieldValue(field) {
    field.val(currentFieldValue);
  }
  function setEmptyStateClasses(field) {
    if (field.val().length == 0) {
      field.closest(".field-input").removeClass("filled");
    }
  }

  $(document).on("focus", ".js-clear-by-focus", function () {
    saveCurrentFieldValue($(this));
    clearFieldByFocus($(this));
  });
  $(document).on("focusout", ".js-clear-by-focus", function () {
    if (currentFieldValue == "") {
      setEmptyStateClasses($(this));
    } else {
      insertSavedCurrentFieldValue($(this));
    }
  });
})();
