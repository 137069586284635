import $ from "jquery";

$(document).on("click touch", "*[data-drop]", function () {
  var target = $(this).attr("data-drop");
  $(".js-dropdown:not(#" + target + ")").removeClass("active");
  $("*[data-drop]").not(this).removeClass("active");
  if (!$("#" + target).hasClass("active")) {
    $("#" + target).addClass("active");
    $(this).addClass("active");
  } else {
    $("#" + target).removeClass("active");
    $(this).removeClass("active");
  }
});

$(document).on("click touch", function (event) {
  if ($(event.target).closest("*[data-drop]").length) {
    return;
  }
  $(".js-dropdown").removeClass("active");
  $("*[data-drop]").removeClass("active");
});

$(document).on("click touch", ".js-dropdown", function (event) {
  if ($(".js-dropdown").hasClass("active")) {
    event.stopPropagation();
  }
});

$(".mdl").on("click", function (event) {
  if ($(".js-dropdown").hasClass("active")) {
    $(".js-dropdown").removeClass("active");
    $("*[data-drop]").removeClass("active");
  }
});
