import $ from "jquery";
import "../plugins/slick.min.js";
// import "../plugins/modernizr-custom.js";

(function () {
  const localSliderOptions = {
    arrows: true,
    appendArrows: ".local-slider-box__arrows",
    dots: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    infinite: true,
    draggable: false,
    swipe: true,
    touchMove: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: false
        }
      }
    ]
  };
  $(".js-local-slider").slick(localSliderOptions);
})();

function localSliderFiltering() {
  let activeTab = $(".js-local-filter-tab.active"),
    filter = activeTab.data("filter");

  $(".js-local-slider").slick("slickUnfilter");
  $(".js-local-slider").slick(
    "slickFilter",
    `.local-slider__slide[data-filter="${filter}"]`
  );
}

const showHideDots = () => {
  setTimeout(() => {
    if ($(".local-slider-box__arrows").find(".slick-arrow").length) {
      $(".local-slider .slick-dots").addClass("active");
    } else {
      $(".local-slider .slick-dots").removeClass("active");
    }
  }, 100);
};

/* was done fow calculating slide width - depricated */
// const getLocalSliderWidth = () => {
//   let jsLocalSliderWidth = $(".js-local-slider").width();
//   document.documentElement.style.setProperty(
//     "--js-local-slider-width",
//     jsLocalSliderWidth + "px"
//   );
// };

if ($(".local-slider").length) {
  $(document).ready(function () {
    localSliderFiltering();
    showHideDots();
    // getLocalSliderWidth();
  });

  $(document).on("click", ".js-local-filter-tab", function () {
    localSliderFiltering();
    showHideDots();
    // getLocalSliderWidth();
  });

  $(window).on("resize orientationchange", function () {
    showHideDots();
    // getLocalSliderWidth();
  });
}
