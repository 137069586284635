import $ from "jquery";
import { runIfElementExist } from "./run-if-element-exist";

function countNumbOfReviewsOnPublicProfile() {
  let review = $(".js-review"),
    reviewNumb = $(".js-reviews-title-numb");

  reviewNumb.text(review.length);
}

runIfElementExist(countNumbOfReviewsOnPublicProfile, ".js-reviews-title-numb");
