import $ from "jquery";

(function () {
  const ownerInfoLocationSetPaddings = () => {
    let lineHeight,
      name = $(".js-owner-info-name"),
      locations = $(".js-owner-info-location"),
      topMargin,
      badge = $(".profile-info__badge");

    if (window.matchMedia("(min-width: 1440px)").matches) {
      lineHeight = 36;
      if (name.height() > lineHeight) {
        topMargin = 0;
      } else {
        if (badge.length) {
          topMargin = lineHeight;
        } else {
          topMargin = 20;
        }
      }
    } else {
      topMargin = 0;
    }

    locations.css("margin-top", `${topMargin}px`);
  };

  const wrapJoindShare = () => {
    let hash = $(".profile-info__data-hash"),
      joined = $(".js-owner-info-joined"),
      devider = $(".profile-info__devider.data-devider.dn.db-m:not(.dn-l)"),
      share = $(".profile-info__data-line"),
      el = $(".profile-info__joined-share-wrap");

    if (window.matchMedia("(min-width: 1024px)").matches) {
      if (!el.length) {
        $("<div></div>", { class: "profile-info__joined-share-wrap" }).appendTo(
          hash
        );
        let wrap = $(".profile-info__joined-share-wrap");
        wrap.append(joined, devider, share);
      }
    } else {
      if (el.length) {
        hash.append(joined, devider, share);
        el.remove();
      }
    }
  };

  $(function () {
    if ($(".js-owner-info").length) {
      ownerInfoLocationSetPaddings();
      wrapJoindShare();
    }
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    if ($(".js-owner-info").length) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        ownerInfoLocationSetPaddings();
        wrapJoindShare();
      }, 250);
    }
  });
})();

$(document).on("ownerInfoStateDefine", function () {
  ownerInfoLocationSetPaddings();
});
