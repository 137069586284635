import $ from "jquery";
import "../plugins/slick.min.js";

(function () {
  const tipsAndTricksSlider = () => {
    const sliderElement = $(".tat__slider");
    if (window.matchMedia("(min-width: 768px)").matches) {
      if (sliderElement.hasClass("slick-initialized")) {
        sliderElement.slick("unslick");
      }
    } else {
      if (sliderElement.hasClass("slick-initialized")) {
        return;
      } else {
        sliderElement.slick({
          arrows: true,
          appendArrows: ".tat__slider-arrows",
          dots: false,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: false,
          infinite: true,
          draggable: true,
          swipe: true,
          touchMove: true,
          adaptiveHeight: true
        });
      }
    }
  };

  $(function () {
    tipsAndTricksSlider();
  });

  $(window).on("orientationchange", function () {
    tipsAndTricksSlider();
  });

  var resizeTimer;

  $(window).on("resize", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      tipsAndTricksSlider();
    }, 250);
  });
})();

// $(document).on("click", ".js-tips-and-tricks-btn", function () {
//   let slide = $(this).closest(".slick-slide");
//   $(this).closest(".slick-list").height(slide.height());
// });
