import $ from "jquery";

function removeAmenitiesItemLastChildBorder() {
  let item = $(".tile__item"),
    itemActive = $(".tile__item.active");
  item.removeClass("tile__item--last");
  itemActive.last().addClass("tile__item--last");
}

$(function () {
  if ($(".tile__item").length) {
    $(document).on("tabs-switched", function () {
      setTimeout(() => {
        removeAmenitiesItemLastChildBorder();
      }, 0);
    });
  }
});
