import $ from "jquery";

(function () {
  /* sleeps value sets in js/site/fields/select.js by select*/
  if ($(".js-main-search-ph-location").length) {
    function replaceMoreAndLessWithHTMLCodes(inputString = '') {
      return inputString.replace("<", "&lt;").replace(">", "&gt;");
    }
    function setLocationAndDatesValuesIntoMobilePlaceholder() {
      let locationPh = $(".js-main-search-ph-location"),
        datePh = $(".js-main-search-ph-dates"),
        sleepsPh = $(".js-main-search-ph-sleeps"),
        sleepsText = $(
          ".field-input--search--sleeps .ui-selectmenu-text"
        ).text(),
        digit = /\d+/,
        dateStart = $(".js-datepicker-search-start"),
        dateStartValue,
        dateEnd = $(".js-datepicker-search-end"),
        dateEndValue,
        wrap = $(".main-search-ph__item");

      locationPh.html(replaceMoreAndLessWithHTMLCodes($(".js-search-location").val()));
      if ($(".js-search-location").val() != "") {
        locationPh.closest(wrap).addClass("filled");
      } else {
        locationPh.closest(wrap).removeClass("filled");
      }

      if (sleepsText.match(digit)) {
        var numb = sleepsText.match(digit);
        numb = numb.join("");
        sleepsPh.html(numb).closest(".main-search-ph__item").addClass("filled");
      }

      dateStartValue = replaceMoreAndLessWithHTMLCodes(dateStart.val());
      dateEndValue = replaceMoreAndLessWithHTMLCodes(dateEnd.val());
      if (dateStart.val() != "") {
        datePh
          .html(window.matchMedia("(max-width: 567px)").matches ? dateStartValue.slice(0, 5) + `-` + dateEndValue.slice(0, 5) : dateStartValue + `-` + dateEndValue)
          .closest(wrap)
          .addClass("filled");
      }
    }

    $(document).on("click", ".js-hide-main-search-btn", function () {
      setLocationAndDatesValuesIntoMobilePlaceholder();
    });

    var resizeTimer;

    $(function () {
      setLocationAndDatesValuesIntoMobilePlaceholder();
    });

    $(window).on("resize", function (e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        setLocationAndDatesValuesIntoMobilePlaceholder();
      }, 100);
    });
  }
})();
