import $ from "jquery";

(function () {
  function transferProTips() {
    let item = $(".js-pro-tips"),
      desktop = $(".js-pro-tips-desktop"),
      mobile = $(".js-pro-tips-mobile");
    if (window.matchMedia("(min-width: 768px)").matches) {
      item.appendTo(desktop);
    } else {
      item.appendTo(mobile);
    }
  }

  $(function () {
    transferProTips();
  });
  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      // Run code here, resizing has "stopped"
      transferProTips();
    }, 150);
  });
})();
