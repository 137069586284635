import $ from "jquery";
import Cropper from "jquery-cropper";

(function () {
  let $image = $("#img-crop"),
    wrap = $(".img-crop-wrap"),
    btn = ".js-set-img-to-crop",
    dataRatioW,
    dataRatioH,
    left,
    top,
    width,
    height,
    rotate;

  let settings = {
    viewMode: 2,
    dragMode: "move",
    toggleDragModeOnDblclick: false,
    minCropBoxWidth: 100,
    minCropBoxHeight: 100,
    movable: true,
    scalable: false,
    // responsive: true,
    // restore: true,
    zoomable: true,
    autoCropArea: 1,
    guides: false,
    background: false,

    ready() {
      /* A shortcut of the ready event. */
      this.cropper
        .setData({
          rotate: rotate
        })
        .setAspectRatio(dataRatioW / dataRatioH)
        .setData({
          x: left,
          y: top,
          width: width,
          height: height
        });
      // .setCropBoxData({
      //   left: left,
      //   top: top,
      //   width: width,
      //   height: height
      // });
    },
    crop(event) {
      //   JSON.stringify(event.detail)
      $(".js-img-crop-data").attr(
        "data-img-crop",
        JSON.stringify(this.cropper.getData())
      );
    },
    cropstart() {
      // imgTransformations(this.cropper);
    },
    cropend() {}
  };

  $image.cropper(settings);

  // Get the Cropper.js instance after initialized
  let cropper = $image.data("cropper");

  // $(document).on(
  //   "mousedown touchstart click",
  //   ".js-reset-img-crop-btn",
  //   function () {
  //     cropper.destroy();
  //     $(".img-crop").data("src", "").siblings("cropper-container").remove();
  //   }
  // );

  $(document).on("mousedown touchstart", btn, function () {
    let src = $(this).data("src"),
      shape = $(this).data("shape"),
      ratio = $(this).data("ratio"),
      splitRatio = ratio.split(" ");

    dataRatioW = splitRatio[0];
    dataRatioH = splitRatio[1];
    left = $(this).data("input-left");
    top = $(this).data("input-top");
    width = $(this).data("input-width");
    height = $(this).data("input-height");
    rotate = $(this).data("input-rotate");

    // cropper.replace(src).setAspectRatio(dataRatioW / dataRatioH);
    cropper.replace(src);

    let wrapWidth = wrap.width(),
      wrapHeight = wrap.height();
    if (wrapWidth / wrapHeight >= 1) {
      wrap
        .addClass("img-crop-wrap--horizontal")
        .removeClass("img-crop-wrap--vertical");
    } else {
      wrap
        .removeClass("img-crop-wrap--horizontal")
        .addClass("img-crop-wrap--vertical");
    }
    if (shape == "circle") {
      wrap.addClass("img-crop-wrap--circle");
    } else {
      wrap.removeClass("img-crop-wrap--circle");
    }
  });

  /* rotate image */
  $(document).on("click", ".js-cropper-rotate", function () {
    let angle = $(this).data("rotate");
    cropper.rotate(angle);
    /* temporary depricated */
    // imgTransformations(cropper);
  });

  if ($image.length) {
    var resizeTimer;

    $(window).on("resize orientationchange", function (e) {
      let url = $(".cropper-hidden").attr("src");
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        cropper.replace(url);
        // cropper.reset();
      }, 250);
    });
  }
})();

/* temporary depricated */
// function imgTransformations(prefix) {
//   let naturalWidth = prefix.getImageData().naturalWidth,
//     naturalHeight = prefix.getImageData().naturalHeight,
//     aspectRatio = prefix.getImageData().aspectRatio,
//     containerWidth = prefix.getContainerData().width,
//     containerHeight = prefix.getContainerData().height,
//     rotate = prefix.getImageData().rotate,
//     cropBoxWidth = prefix.getCropBoxData().width,
//     cropBoxHeight = prefix.getCropBoxData().height;

//   if (Math.abs(rotate) == 90 || Math.abs(rotate) == 270) {
//     // rotated 90/270 degrees
//     let canvasHeight = prefix.getCanvasData().height,
//       canvasWidth = prefix.getCanvasData().width,
//       cropBoxTop = prefix.getCropBoxData().top,
//       cropBoxLeft = prefix.getCropBoxData().left;

//     // prefix.setCanvasData({ top: 0, left: 0 });
//     // prefix.moveTo(0, 0);
//     // prefix.setCanvasData({
//     //   top: containerHeight / 2 - canvasHeight / 2,
//     //   left: containerWidth / 2 - canvasWidth / 2
//     // });
//     // prefix.moveTo(
//     //   containerWidth / 2 - canvasWidth / 2,
//     //   containerHeight / 2 - canvasHeight / 2
//     // );

//     if (naturalWidth <= naturalHeight) {
//       // VERTICAL ORIENTED IMAGE

//       prefix.setCanvasData({
//         // width: containerWidth,
//         // height: containerWidth * aspectRatio
//       });

//       if (cropBoxTop < containerHeight / 2 - canvasHeight / 2) {
//         // console.log("need move down");
//         prefix.setCropBoxData({
//           // top: containerHeight / 2 - canvasHeight / 2
//           // top: prefix.getCanvasData().top
//         });
//       }
//     } else if (naturalWidth > naturalHeight) {
//       // HORIZONTAL ORIENTED IMAGE

//       prefix.setCanvasData({
//         // width: containerHeight / aspectRatio,
//         // height: containerHeight
//       });

//       if (cropBoxLeft < containerWidth / 2 - canvasWidth / 2) {
//         // console.log("need move right");
//         prefix.setCropBoxData({
//           // left: containerWidth / 2 - canvasWidth / 2
//           // left: prefix.getCanvasData().left
//         });
//         // console.log(
//         //   prefix.getCanvasData().left,
//         //   prefix.getCropBoxData().left
//         // );
//       }
//     }
//   } else {
//     /* rotated 0/180 degrees */
//     prefix.setCanvasData({
//       // width: containerWidth,
//       // height: containerHeight
//       // left: 0,
//       // top: 0
//     });
//   }
//   if (cropBoxWidth > prefix.getCanvasData().width) {
//     prefix.setCropBoxData({
//       // width: prefix.getCanvasData().width
//     });
//   }
//   if (cropBoxHeight > prefix.getCanvasData().height) {
//     prefix.setCropBoxData({
//       // height: prefix.getCanvasData().height
//     });
//   }
// }
