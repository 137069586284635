import $ from "jquery";
import "../plugins/slick.min.js";
import { setRvCardSize } from "../set-rv-card-size";
import { setTooltipData } from "../tooltip";

let sliderElement;
const toGetYouStartedSlider = () => {
  sliderElement = $(".js-to-get-slider");

  if (window.matchMedia("(min-width: 768px)").matches) {
    if (sliderElement.hasClass("slick-initialized")) {
      sliderElement.slick("unslick");
    }
  } else {
    if (sliderElement.hasClass("slick-initialized")) {
      return;
    } else {
      sliderElement.slick({
        arrows: true,
        appendArrows: false,
        dots: true,
        appendDots: ".js-to-get-slider-dots",
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        draggable: true,
        swipe: true,
        touchMove: true,
        mobileFirst: true,
        infinite: true,
        adaptiveHeight: true,
        slide: '.rv-featured-slider__item',
        responsive: [
          {
            breakpoint: 559,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      }).on('setPosition', function (event, slick) {
        slick.$slides.parent().find("> div").css('height', slick.$slideTrack.height() + 'px');
      });
    }
  }
};

if ($(".js-to-get-slider").length) {
  $(function () {
    toGetYouStartedSlider();
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      toGetYouStartedSlider();
    }, 250);
  });
}

function destroySlick() {
  if (window.matchMedia("(max-width: 767px)").matches) {
    if (sliderElement.hasClass("slick-initialized")) {
      sliderElement.slick("unslick");
    }
  }
}

let toGetTab = $(".js_tab_select");
if (toGetTab.length) {
  $(document).on("tabs-switched", function () {
    let elem = $(".js_tab_select.active");
    ajaxForToGetTabs(elem);
  });
}
function ajaxForToGetTabs(elem) {
  $.ajax({
    url: $("#http_root").val() + "/site/ajax/home/rv_featured.php",
    data: {
      id: elem.hasClass("active") ? elem.data("id") : null
    },
    cache: false,
    dataType: "json",
    timeout: 60000,
    type: "post",
    complete: function (data, textStatus) {
      destroySlick();
      $("#rv_featured")
        .html(data.responseText)
        .promise()
        .done(function () {
          setRvCardSize();
          toGetYouStartedSlider();
          setTooltipData()
        });
    }
  });
}
