import $ from "jquery";

export function tabsUnderlineTranslateX() {
  let tabsParent = $(".tabs-underline");
  tabsParent.each(function () {
    if (!($(this).find(".tabs-underline__tab.active"))) return false
    let activeTab = $(this).find(".tabs-underline__tab.active"),
      positionLeft = activeTab.position().left,
      bulbWidth = activeTab.width();
    $(this)
      .find(".tabs-underline__bulb")
      .css({
        width: bulbWidth + "px",
        transform: `translateX(${positionLeft}px)`
      });
  });
}

$(document).on("tabs-switched", function () {
  tabsUnderlineTranslateX();
});

if ($(".tabs-underline").length) {
  $(function () {
    tabsUnderlineTranslateX();
  });
  $(window).on("resize orientationchange", function () {
    tabsUnderlineTranslateX();
  });
}
