import $ from "jquery";

(function () {
  let state = false;

  $(document).on("click", ".field-input--textarea", function () {
    if (!state) {
      $(this).find("textarea").trigger("focus");
    }
  });

  $(document).on("focus", "textarea", function () {
    state = true;
  });
  $(document).on("focusout", "textarea", function () {
    state = false;
  });
})();
