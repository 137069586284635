import $ from "jquery";

(function () {
  let field = $(".js-rv-class-price-value"),
    tab = $(".js-tab-rv-class.active");
  field.text(tab.attr("data-price"));

  function setRvClassPriceValue(button) {
    field.text(button.attr("data-price"));
  }

  $(document).on("click", ".js-tab-rv-class", function () {
    setRvClassPriceValue($(this));
  });
})();
