import $ from "jquery";

const collapsingTextStateDefine = () => {
  let item = $(
      ".collapsing-txt:not(.collapsing-txt--no-collapse) .collapsing-txt__inner"
    ),
    maxHeight;

  /* public vehicle page */
  if (item.closest(".collapsing-txt--default").length) {
    if (window.matchMedia("(min-width: 0px)").matches) {
      maxHeight = 144;
    } else if (window.matchMedia("(min-width: 768px)").matches) {
      maxHeight = 135;
    } else if (window.matchMedia("(min-width: 1024px)").matches) {
      maxHeight = 162;
    } else if (window.matchMedia("(min-width: 1680px)").matches) {
      maxHeight = 135;
    }
  }
  /* public vehicle page: tips and tricks block */
  // if (item.closest(".collapsing-txt--tips-and-tricks").length) {
  //   if (window.matchMedia("(min-width: 0px)").matches) {
  //     maxHeight = 120;
  //   } else if (window.matchMedia("(min-width: 768px)").matches) {
  //     maxHeight = 108;
  //   } else if (window.matchMedia("(min-width: 1024px)").matches) {
  //     maxHeight = 135;
  //   }
  // }

  item.each(function () {
    if ($(this).height() <= maxHeight) {
      $(this).closest(".collapsing-txt").addClass("collapsing-txt--no-expand");
    } else {
      $(this)
        .closest(".collapsing-txt")
        .removeClass("collapsing-txt--no-expand");
    }
  });
};

$(document).ready(function () {
  collapsingTextStateDefine();
});

$(window).on("resize orientationchange", function () {
  collapsingTextStateDefine();
});
