import $ from "jquery";

/* Show on Radio Check */
$(function () {
  $("*[data-show-on-radio-check]").each(function () {
    var arr = $(this).data("show-on-radio-check");
    var target = arr.split(" ");
    for (var i = 0; i < target.length; i++) {
      if ($(this).is(":checked") && $(this).val() == "1") {
        $("#" + target[i]).addClass("active");
        if ($("#" + target[i]).find(".styler").length) {
          refreshStyler();
        }
      } else {
        $("#" + target[i]).removeClass("active");
      }
    }
  });
});

$(document).on("change", "*[data-show-on-radio-check]", function () {
  var arr = $(this).data("show-on-radio-check");
  var target = arr.split(" ");
  for (var i = 0; i < target.length; i++) {
    if ($(this).is(":checked") && $(this).val() == "1") {
      $("#" + target[i]).addClass("active");
      if ($("#" + target[i]).find(".styler").length) {
        refreshStyler();
      }
    } else {
      $("#" + target[i]).removeClass("active");
    }
  }
});
/*  */
