import $ from "jquery";

function setAmenitiesTabLineState() {
  let item = $(".js-amenities-filter-tab:last-child"),
    itemWrap = $(".tabs-underline--amenities"),
    itemOffsetLeft = item.offset().left,
    itemWidth = item.outerWidth(),
    viewportWidth = window.innerWidth,
    pipe = $(".pipe--amenities");
  if (itemOffsetLeft + itemWidth <= viewportWidth) {
    pipe.addClass("pipe--amenities--extreme-right");
  } else {
    pipe.removeClass("pipe--amenities--extreme-right");
  }
}

if ($(".js-amenities-filter-tab").length) {
  $(".pipe__inner").on("scroll", function () {
    setAmenitiesTabLineState();
  });

  $(function () {
    setAmenitiesTabLineState();
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      setAmenitiesTabLineState();
    }, 250);
  });
}
