import $ from "jquery";
import "../plugins/slick.min.js";

(function () {
  const publicVehicleMobileSlider = () => {
    const sliderElement = $(".public-vehicle-mobile-slider__slider");
    if (window.matchMedia("(min-width: 768px)").matches) {
      if (sliderElement.hasClass("slick-initialized")) {
        sliderElement.slick("unslick");
      }
    } else {
      if (sliderElement.hasClass("slick-initialized")) {
        return;
      } else {
        sliderElement.slick({
          arrows: true,
          dots: false,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: false,
          infinite: true,
          draggable: true,
          swipe: true,
          touchMove: true,
          adaptiveHeight: true,
          asNavFor: ".mdl-slider"
        });
      }
    }
  };

  (function slideCount() {
    const $slickPublicPhotosMobile = $(".public-vehicle-mobile-slider__slider");

    $slickPublicPhotosMobile.on(
      "init reInit afterChange",
      function (event, slick, currentSlide, nextSlide) {
        let i = (currentSlide ? currentSlide : 0) + 1;
        $(".js-slide-index-mobile").html(i);
        $(".js-slide-count-mobile").html(slick.slideCount);
      }
    );
  })();

  (function slideArrowAction() {
    const $slickPublicPhotosMobile = $(".public-vehicle-mobile-slider__slider");

    $slickPublicPhotosMobile.on("init reInit afterChange", function () {
      const $slickPublicPhotosMobileArrowRight = $(
        ".public-vehicle-mobile-slider__slider .slick-next"
      );
      const $slickPublicPhotosMobileArrowLeft = $(
        ".public-vehicle-mobile-slider__slider .slick-prev"
      );
      $slickPublicPhotosMobileArrowRight.on("click", function (event) {
        event.stopPropagation();
      });
      $slickPublicPhotosMobileArrowLeft.on("click", function (event) {
        event.stopPropagation();
      });
    });
  })();

  $(function () {
    publicVehicleMobileSlider();
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      // Run code here, resizing has "stopped"
      publicVehicleMobileSlider();
    }, 150);
  });
})();
