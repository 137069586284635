import $ from "jquery";

$(document).on("click", ".js-accord-head", function () {
  var parent = $(".js-accord"),
    btn = $(".js-accord-head");

  $(this).toggleClass("active").siblings(".js-accord-body").slideToggle();

  $(this)
    .closest(parent)
    .toggleClass("active")
    .siblings(parent)
    .removeClass("active");

  if (!$(this).closest(".js-accord").hasClass("js-unbound")) {
    $(this)
      .closest(".js-accord")
      .siblings()
      .find(btn)
      .not($(this))
      .each(function (i) {
        $(this).removeClass("active").siblings(".js-accord-body").slideUp();
      });
  }

  if ($(this).siblings(".js-accord-body").find("select").length) {
  }
});
