import $ from "jquery";

function calculateScrollShadow(node) {
  const isScroll = node.scrollHeight > node.offsetHeight;
  
  if (isScroll) {
    const nodeHeight = node.offsetHeight;
    const nodeScrollHeight = node.scrollHeight;
    const nodeScrollTop = node.scrollTop;
    const nodeScrollBottom = nodeScrollHeight - nodeHeight - nodeScrollTop;
    return {
      top: nodeScrollTop > 0,
      bottom: nodeScrollBottom > 1
    };
  }
  return {
    top: false,
    bottom: false
  };
}

function handleScroll(node, targetTopNodeClass, targetBottomNodeClass, shadowClass) {
  const data = calculateScrollShadow(node);

  if (targetTopNodeClass && data?.top) {
    $(targetTopNodeClass).addClass(shadowClass)
  } else {
    $(targetTopNodeClass).removeClass(shadowClass)
  }

  if (targetBottomNodeClass && data?.bottom) {
    $(targetBottomNodeClass).addClass(shadowClass)
  } else {
    $(targetBottomNodeClass).removeClass(shadowClass)
  }
}

$(function () {
  if ($(".mdl-list-your-rv__content-inner1").length) {
    const node = $(".mdl-list-your-rv__content-inner1")[0];

    handleScroll(node, null, '.mdl-list-your-rv__footer', 'active-shadow');

    $(node).on("scroll", function () {
      handleScroll(this, null, '.mdl-list-your-rv__footer', 'active-shadow');
    });

    $(window).on("resize orientationchange", function () {
      handleScroll(node, null, '.mdl-list-your-rv__footer', 'active-shadow');
    });
  }
});

