import $ from "jquery";

function runIfElementExist(script, identifier) {
  $(function () {
    if ($(identifier).length) {
      script();
    }
  });
}

export { runIfElementExist };
