import $ from "jquery";

(function () {
  const rvDetailsTransfer = () => {
    let mobileContainer = $(".js-rv-details-mobile-container"),
      desktopContainer = $(".js-rv-details-desktop-container"),
      transferBlock = $(".js-rv-details-transfer-block");
    if (window.matchMedia("(min-width: 768px)").matches) {
      if (desktopContainer.find(transferBlock).length) {
        return;
      } else {
        transferBlock.appendTo(desktopContainer);
      }
    } else {
      if (mobileContainer.find(transferBlock).length) {
        return;
      } else {
        transferBlock.appendTo(mobileContainer);
      }
    }
  };
  const calculateDetailsTotal = () => {
    let tile = $(".js-details-item");
    $(".js-details-quont").html(tile.length);
  };
  $(document).ready(function () {
    rvDetailsTransfer();
    calculateDetailsTotal();
  });
  $(window).on("resize orientationchange", function () {
    rvDetailsTransfer();
  });
})();
