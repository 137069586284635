import $ from "jquery";

(function () {
  function setCkeOlListNumbs() {
    let list = $(".cke-clp ol"),
      item,
      numb;

    list.each(function () {
      item = $(this).find("li");
      item.each(function (i) {
        numb = i + 1;
        let span = $(`<span class="cke-ol-numb">${numb}</span>`);
        if ($(this).find(".cke-ol-numb").length < 1) {
          span.prependTo($(this));
        }
      });
    });
    setTimeout(() => {
      setWidthOfCkeOlListNumbs(list);
    }, 0);
  }

  function setWidthOfCkeOlListNumbs(list) {
    list.each(function () {
      let item = $(this).find("li span:first-child"),
        width = 0;
      item.each(function (i) {
        if ($(this).width() > width) {
          width = $(this).width();
        }
      });
      item.css("min-width", `${width}px`);
    });
  }

  $(function () {
    setCkeOlListNumbs();
  });

  let resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      setCkeOlListNumbs();
    }, 500);
  });
})();
