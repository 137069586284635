import $ from "jquery";

function setRvCardSize() {
  let card = $(".rv-card"),
    smallCol = 359 /* 0 <= size <= 359 */,
    mediumCol = 415 /* 360 <= size <= 415  */,
    mediumLargeCol = 495 /* 416 <= size <= 495  */,
    largeCol = 855 /* 496 <= size <= 855 */,
    smallRow = 891 /* 856 <= size <= 891 */,
    mediumRow = 1023; /* 892 <= size <= 1023 */
  /* largeRow >= 1024 */

  card.each(function () {
    let row = "rv-card--row",
      col = "rv-card--col",
      small = "rv-card--s",
      medium = "rv-card--m",
      mediumLarge = "rv-card--ml",
      large = "rv-card--l",
      cardWidth = $(this).outerWidth();

    if (cardWidth > mediumRow) {
      $(this)
        .addClass(`${row} ${large}`)
        .removeClass(`${col} ${small} ${medium} ${mediumLarge}`);
    } else if (cardWidth > smallRow && cardWidth <= mediumRow) {
      $(this)
        .addClass(`${row} ${medium}`)
        .removeClass(`${col} ${small} ${mediumLarge} ${large}`);
    } else if (cardWidth > largeCol && cardWidth <= smallRow) {
      $(this)
        .addClass(`${row} ${small}`)
        .removeClass(`${col} ${medium} ${mediumLarge} ${large}`);
    } else if (cardWidth > mediumLargeCol && cardWidth <= largeCol) {
      $(this)
        .addClass(`${col} ${large}`)
        .removeClass(`${row} ${small} ${medium} ${mediumLarge}`);
    } else if (cardWidth > mediumCol && cardWidth <= mediumLargeCol) {
      $(this)
        .addClass(`${col} ${mediumLarge}`)
        .removeClass(`${row} ${small} ${medium} ${large}`);
    } else if (cardWidth > smallCol && cardWidth <= mediumCol) {
      $(this)
        .addClass(`${col} ${medium}`)
        .removeClass(`${row} ${small} ${mediumLarge} ${large}`);
    } else if (cardWidth <= smallCol) {
      $(this)
        .addClass(`${col} ${small}`)
        .removeClass(`${row} ${medium} ${mediumLarge} ${large}`);
    }

    $(".rv-card__spinner").removeClass("active");
  });
  $(".rv-card__spinner").removeClass("active");
}

if ($(".rv-card").length) {
  $(function () {
    setRvCardSize();
  });

  $(window).on("load resize orientation", function () {
    setRvCardSize();
  });
}

export { setRvCardSize };
