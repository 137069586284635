import $ from "jquery";
import "jquery-ui/ui/widgets/selectmenu";
import { runIfElementExist } from "./run-if-element-exist";

function displayPublicVehicleFilterSelect() {
  let selectUser = $(".js-user-filter-select"),
    selectVehicle = $(".js-public-vehicle-filter-select"),
    col = selectVehicle.closest(".reviews__field-col");
  if (selectUser.val() == "As a renter") {
    col.removeClass("active");
  } else {
    col.addClass("active");
  }
}

$(".js-user-filter-select").basicselect({
  select: function (event, ui) {
    if (ui.item.index !== 0 || (ui.item.index === 0 && ui.item.value !== "")) {
      $(this).closest(".field-input").addClass("filled");
    } else {
      $(this).closest(".field-input").removeClass("filled");
    }
    displayPublicVehicleFilterSelect();
    // $(document).trigger("publicProfileSelected");
    if (typeof profileReviewsFilter == "function") {
      profileReviewsFilter();
    }
  }
});
$(".js-public-vehicle-filter-select").basicselect({
  select: function (event, ui) {
    // $(document).trigger("publicProfileSelected");
    if (typeof profileReviewsFilter == "function") {
      profileReviewsFilter();
    }
  }
});

runIfElementExist(displayPublicVehicleFilterSelect, ".js-user-filter-select");
