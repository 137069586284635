import $ from "jquery";

function getParent($input) {
  return $input.closest(".field-input");
}

function setFilled($parent, $el) {
  $parent.toggleClass("filled", $el.val() !== "" || !!$el.attr("placeholder"));
}

function focusHandler() {
  getParent($(this)).addClass("focused");
}

function blurHandler() {
  getParent($(this)).removeClass("focused");
}

$(document)
  .on("change", ".js-field-state", function () {
    const $input = $(this);
    const $parent = getParent($input);

    setFilled($parent, $input);
  })
  .on("focus", ".js-field-state", focusHandler)
  .on("blur", ".js-field-state", blurHandler);

$(function () {
  $(".js-field-state").each(function () {
    const $input = $(this);
    const $parent = getParent($input);

    setFilled($parent, $input);

    $parent.toggleClass("disabled", $input.is(":disabled"));
  });
});
