import $ from "jquery";
import "../plugins/slick.min.js";

/* **** MDL FULL SIZE SLIDER   ***** */
const addSlidesToSlickSlider = (slideData) => {
  if (!$(".mdl-slider").find(".mdl-slider__slide").length) {
    const arr = slideData;
    let part = "";
    arr.map(function (item) {
      if (item.caption) {
        part = `<div class="mdl-slider__caption">${item.caption}</div>`;
      } else {
        part = ``;
      }
      let $slide = $(
        `<div class="mdl-slider__slide">
          <div class="mdl-slider__slide-spacer">
            <div class="mdl-slider__media">
                <img class="mdl-slider__img" src="${item.img}" alt=""/>
            </div>
            ${part}
          </div>
        </div>`
      );
      $slide.appendTo(".mdl-slider");
    });
  }
};

const mdlFullSizeSliderInit = (clickedElement) => {
  const sliderData = clickedElement.data("slider");
  let arrowsContainer;
  if (window.matchMedia("(min-width: 1024px)").matches) {
    arrowsContainer = ".mdl__slider-arrows-desktop";
  } else {
    arrowsContainer = ".mdl__slider-arrows-mobile";
  }
  let navFor;
  if ($(".public-vehicle-mobile-slider__slider").length) {
    if (
      $(".public-vehicle-mobile-slider__slider").hasClass("slick-initialized")
    ) {
      navFor = ".public-vehicle-mobile-slider__slider";
    } else {
      navFor = null;
    }
  } else {
    navFor = null;
  }

  addSlidesToSlickSlider(sliderData);

  // && clickedElement.hasClass(".public-vehicle-mobile-slider__slider")
  if (!$(".mdl-slider").hasClass("slick-initialized")) {
    const mdlOptions = {
      arrows: true,
      appendArrows: arrowsContainer,
      dots: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      draggable: false,
      swipe: true,
      touchMove: true,
      adaptiveHeight: false,
      asNavFor: navFor
    };
    $(".mdl-slider").slick(mdlOptions);
  }
};

$(document).on("click", ".js-init-slider", function () {
  let mdlSlider = $(".mdl-slider");
  if (mdlSlider.hasClass("slick-initialized")) {
    mdlSlider.slick("unslick").empty();
  }
  mdlFullSizeSliderInit($(this));
});

$(
  (function () {
    const $slickElement = $(".mdl-slider");

    $slickElement.on(
      "init reInit afterChange",
      function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)

        let i = (currentSlide ? currentSlide : 0) + 1;
        $(".js-mdl-slide-index").html(i);
        $(".js-mdl-slide-count").html(slick.slideCount);
      }
    );
  })()
);

$(document).on("click", ".js-mdl-slider-destroy", function () {
  $(".mdl-slider").slick("unslick").empty();
});

$(document).on(
  "touchstart mousedown",
  ".public-vehicle-mobile-slider__slider",
  function () {
    mdlFullSizeSliderInit($(this));
  }
);

// $(document).on("click", ".js-img-to-slider", function () {
//   const parent = $("#" + $(this).data("img-to-slider-parent"));
//   parent.find(".js-img-to-slider").each(function (i) {
//     $(this).attr("data-index", i);
//   });
//   var index = $(this).attr("data-index");

//   $(".mdl-slider").slick("slickGoTo", index);
//   $(".mdl-slider").slick("refresh");
// });
