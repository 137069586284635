import $ from "jquery";

(function () {
  function switchToGetYouStartedSelectValue(button) {
    let select = $(".js-to-get-tabs-select"),
      value = function () {
        let text;
        if (!button.hasClass("js-tab-to-get-all")) {
          text = button.find(".to-get-tabs__label").text();
        } else {
          text = "";
        }
        return text;
      },
      hint = button
        .closest(".to-get-tabs__item")
        .find(".to-get-tabs__hint")
        .text(),
      fieldValue = $(".js-to-get-tabs-select-value"),
      fieldHint = $(".js-to-get-tabs-hint-mobile");

    fieldValue.text(value);
    fieldHint.text(hint);

    if (button.hasClass("js-tab-to-get-all")) {
      select.removeClass("filled");
    } else {
      if (window.matchMedia("(min-width: 768px)").matches) {
        if (button.hasClass("active")) {
          select.addClass("filled");
          fieldValue.text(value);
          fieldHint.text(hint);
        } else {
          select.removeClass("filled");
          fieldValue.text("");
          fieldHint.text("");
        }
      } else {
        select.addClass("filled");
      }
    }

    if (hint == "") {
      fieldHint.removeClass("active");
    } else {
      fieldHint.addClass("active");
    }
  }
  $(document).on("click", ".js-tab-to-get", function () {
    switchToGetYouStartedSelectValue($(this));
  });
})();
