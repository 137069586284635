import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";
// import { setDatePickerPositionOnHomePage } from "../set-datepicker-position-on-home-page";

function patchMethod(baseObj, methodName, implementation) {
  const oldMethod = baseObj[methodName];

  baseObj[methodName] = function (...args) {
    const result = oldMethod.apply(this, args);

    args.unshift(result);

    return implementation.apply(this, args);
  };
}

function setDateToPlaceholder() {
  const dateStart = $(".js-datepicker-search-start").val()
  const dateEnd = $(".js-datepicker-search-end").val()
  const dateStartPlaceholder = $(".js-datepicker-search-start-placeholder")
  const dateEndPlaceholder = $(".js-datepicker-search-end-placeholder")

  if (dateStart.length && dateEnd.length) {
    if (window.matchMedia("(max-width: 567px)").matches) {
      dateStartPlaceholder.text(dateStart.slice(0, 5));
      dateEndPlaceholder.text(dateEnd.slice(0, 5));
    } else {
      dateStartPlaceholder.text(dateStart);
      dateEndPlaceholder.text(dateEnd);
    }
  }
}

function extractDate($cell) {
  if (!$cell) {
    return null;
  }

  const { year, month } = $cell.data();

  if (!year) {
    return null;
  }

  const day = $cell.text().trim();

  return new Date(year, month, day);
}

function orderDates(rangeStart, rangeEnd) {
  if (rangeStart > rangeEnd) {
    return [rangeEnd, rangeStart];
  }

  return [rangeStart, rangeEnd];
}

function paintDatesBetween($container, $cell, firstDate, optionalSecondDate) {
  const secondDate = optionalSecondDate
    ? optionalSecondDate
    : extractDate($cell);

  if (!secondDate) {
    return;
  }

  const [rangeStart, rangeEnd] = orderDates(firstDate, secondDate);

  $container.find(".ui-datepicker-calendar td").each(function () {
    const $ele = $(this);
    const date = extractDate($ele);

    if (!date) {
      return;
    }

    if (date >= rangeStart && date <= rangeEnd) {
      $ele.addClass("ui-datepicker-calendar-range");

      if (date.toISOString() === rangeStart.toISOString()) {
        $ele.addClass("ui-datepicker-calendar-range--start");
      }

      if (date.toISOString() === rangeEnd.toISOString()) {
        $ele.addClass("ui-datepicker-calendar-range--end");
      }
    }
  });
}

function bindHover(inst, $container) {
  const selector = ".ui-datepicker-calendar td";

  $container
    .on("mouseout.datepicker", selector, function () {
      if (!inst.rangeStartDate || !inst.rangeEndDate) {
        $container
          .find(".ui-datepicker-calendar-range")
          .removeClass("ui-datepicker-calendar-range")
          .removeClass("ui-datepicker-calendar-range--start")
          .removeClass("ui-datepicker-calendar-range--end");
      }
    })
    .on("mouseover.datepicker", selector, function () {
      const $cell = $(this);

      if (!inst.rangeStartDate) {
        return;
      }

      if (!inst.rangeEndDate) {
        paintDatesBetween($container, $cell, inst.rangeStartDate);
      }
    });

  paintDatesBetween($container, null, inst.rangeStartDate, inst.rangeEndDate);
}

/**
 * We add some functionality to the datepicker in this function
 */
function patchDatepicker() {
  patchMethod($.datepicker, "_updateDatepicker", (result, inst) => {
    const { numberOfMonths } = inst.settings;

    if (typeof numberOfMonths === "number" && numberOfMonths > 1) {
      const gutter = 48 * (numberOfMonths - 1);
      const panelWidth = 17 * numberOfMonths;

      inst.dpDiv.width(`calc(${panelWidth}em + ${gutter}px)`);
    }

    return result;
  });

  patchMethod($.datepicker, "_showDatepicker", (result, inputOrEvent) => {
    const input = inputOrEvent.target || inputOrEvent;
    const inst = $.datepicker._getInst(input);

    bindHover(inst, inst.dpDiv);

    return result;
  });

  patchMethod($.datepicker, "_hideDatepicker", (result) => {
    const inst = $.datepicker._curInst;

    inst.dpDiv.off(
      "mouseover.datepicker mouseout.datepicker",
      ".ui-datepicker-calendar td"
    );

    return result;
  });
}

patchDatepicker();

$.fn.datePicker = function (options) {
  return this.each((index, element) => {
    const $ele = $(element);
    const rangeStartId = $ele.data("rangeStart");
    const rangeEndId = $ele.data("rangeEnd");

    const mainSearchLocation = $(".field-input--search--location");
    const mainSearchSleeps = $(".field-input--search--sleeps");
    const mainSearchBtn = $(".main-search__btn");

    const mobileSearchCloseBtn = $(".js-hide-main-search-btn");

    // simple date picker
    if (!rangeStartId && !rangeEndId) {
      $ele.datepicker({
        ...options,
        firstDay: 0,
        numberOfMonths: 1,
        showOtherMonths: true,
        minDate: new Date()
      });

      return;
    }

    let topNavSearch = $(".top-nav-search"),
      field = $(".js-datepicker-search");
    // first date picker
    if (rangeEndId) {
      $ele.datepicker({
        ...options,
        firstDay: 0,
        numberOfMonths: 2,
        showOtherMonths: true,
        minDate: new Date(),
        dateFormat: "mm/dd/yy",
        beforeShow(input, inst) {
          const rangeStartDate = $.datepicker.parseDate(
            $.datepicker._get(inst, "dateFormat"),
            $(input).val(),
            $.datepicker._getFormatConfig(inst)
          );
          const rangeEndDate = $.datepicker.parseDate(
            $.datepicker._get(inst, "dateFormat"),
            $("#" + rangeEndId).val(),
            $.datepicker._getFormatConfig(inst)
          );

          inst.rangeStartDate = rangeStartDate;
          inst.rangeEndDate = rangeEndDate;

          if ($(".hero-home").length) {
            $(".ui-datepicker").addClass("ui-datepicker--home");
          }

          if (topNavSearch.length) {
            topNavSearch.addClass("datepicker-opened");
          }

          mobileSearchCloseBtn.prop("disabled", true);
          mainSearchLocation.addClass("disabled");
          mainSearchSleeps.addClass("disabled");
          mainSearchBtn.addClass("disabled");
        },

        onSelect(dateText, inst) {
          $(document).on("mousedown", $.datepicker._checkExternalClick);
          const { rangeStartDate, rangeEndDate } = inst;

          function setStartFieldData(value) {
            inst.inline = true;
            inst.rangeStartDate = date;
            inst.rangeEndDate = null;

            $(inst.input).val(value).trigger("change");
          }

          function setBothFieldsData() {
            function format(d) {
              return $.datepicker.formatDate(
                $.datepicker._get(inst, "dateFormat"),
                d,
                $.datepicker._getFormatConfig(inst)
              );
            }
            
            const [startDate, endDate] = orderDates(rangeStartDate, date);

            if (startDate?.toISOString() === endDate?.toISOString()) {
              $(inst.input).val(fieldStartValue).trigger("change");
              return;
            }
            inst.rangeStartDate = rangeStartDate;
            inst.rangeEndDate = date;

            $(inst.input).val(format(startDate)).trigger("change")
            $("#" + rangeEndId).val(format(endDate)).trigger("change");

            setDateToPlaceholder()

            inst.inline = false;
          }

          const fieldStartValue = inst?.lastVal;
          const fieldEndValue = $("#" + rangeEndId).val();
          const isFieldsEmpty = Boolean(!fieldStartValue && !fieldEndValue);
          const isFieldsFull = Boolean(fieldStartValue && fieldEndValue)
          const isEmptyStateStart = Boolean(isFieldsEmpty && !rangeStartDate && !rangeEndDate)
          const isEmptyStateEnd = Boolean(isFieldsEmpty && rangeStartDate && !rangeEndDate)
          const isFullStateStart = Boolean(isFieldsFull && rangeStartDate && rangeEndDate)
          const isFullStateEnd = Boolean(isFieldsFull && rangeStartDate && !rangeEndDate)
          const date = new Date(
            inst.currentYear,
            inst.currentMonth,
            inst.currentDay
          );

          if (isEmptyStateStart) {
            return setStartFieldData("")
          } else if (isEmptyStateEnd) {
            return setBothFieldsData();
          } else if (isFullStateStart) {
            return setStartFieldData(fieldStartValue)
          } else if (isFullStateEnd) {
            return setBothFieldsData();
          }
        },

        onClose(dateText, inst) {
          inst.inline = false;

          $("#" + rangeEndId).blur();
          $(this).blur();

          if (topNavSearch.length) {
            topNavSearch.removeClass("datepicker-opened");
          }

          mobileSearchCloseBtn.prop("disabled", false);
          mainSearchLocation.removeClass("disabled");
          mainSearchSleeps.removeClass("disabled");
          mainSearchBtn.removeClass("disabled");
        }
      });

      setDateToPlaceholder()

      $(window).on("resize orientationchange", function (e) {
        $.datepicker._checkExternalClick(e)
        setDateToPlaceholder()
      })
    }

    // second date picker
    if (rangeStartId) {
      $ele.on("focus", () => {
        $("#" + rangeStartId).trigger("focus");
      });
    }
  });
};

$(".js-datepicker").datePicker();

//TODO init CWRR
if (!window.CWRR) {
  window.CWRR = {};
}

window.CWRR.Datepicker = {
  init: (node, options) => $(node).datePicker(options),
  destroy: (node) => $(node).datePicker("destroy")
};

/* no need if left position of .ui-datepickerl is defined in CSS */
// (function () {
//   var resizeTimer;

//   $(window).on("resize orientationchange", function (e) {
//     clearTimeout(resizeTimer);
//     resizeTimer = setTimeout(function () {
//       var field = $(document.activeElement);
//       if (field.is(".hasDatepicker")) {
//         field.datepicker("hide").datepicker("show");
//       }
//     }, 100);
//   });
// })();
