import $ from "jquery";

(function () {
  function countArticleInFirstCol() {
    let quont = $('.js-col-semi[data-col-semi="1"]').find(".js-article-semi")
      .length;
    return quont;
  }

  $(document).on("slick-stop", function () {
    let articleLength = countArticleInFirstCol();
    transitTrendingArticles();
    // console.log(articleLength);
  });

  function transitTrendingArticles() {
    let colSemi = $(".js-col-semi"),
      colSemiFirst = $('.js-col-semi[data-col-semi="1"]'),
      colQuarter = $(".js-col-quarter"),
      colQuarterFirst = $('.js-col-quarter[data-col-quarter="1"]'),
      space = $(".trending__space"),
      articleSemi = $(".js-article-semi"),
      articleQuarter = $(".js-article-quarter");

    if (window.matchMedia("(min-width: 1024px)").matches) {
      if (colSemiFirst.find(articleSemi).length <= 1) {
        // console.log("fire");

        colSemi.each(function () {
          $(this).find(articleSemi).appendTo(colSemiFirst.find(space));
        });
        colQuarter.each(function () {
          $(this).find(articleQuarter).appendTo(colQuarterFirst.find(space));
        });
      }
    } else {
      articleSemi.each(function (i) {
        let index = $(this).data("article"),
          col = $(`.js-col-semi[data-col-semi="${index}"]`),
          article = $(`.js-article-semi[data-article="${index}"]`);
        if (col.find(article).length == 0) {
          article.appendTo(col.find(space));
        }
      });
      articleQuarter.each(function (i) {
        let index = $(this).data("article"),
          col = $(`.js-col-quarter[data-col-quarter="${index}"]`),
          article = $(`.js-article-quarter[data-article="${index}"]`);
        if (col.find(article).length == 0) {
          article.appendTo(col.find(space));
        }
      });
    }
  }

  $(function () {
    transitTrendingArticles();
  });

  var resizeTimer;

  $(window).on("resize orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      transitTrendingArticles();
      setTimeout(() => {
        transitTrendingArticles();
      }, 0);
    }, 100);
  });
})();
