import $ from "jquery";

(function () {
  const ownerInfoStateDefine = () => {
    let info = $(".js-owner-info"),
      aboutText = $(".js-owner-info-about-text"),
      locations = $(".js-owner-info-location");

    if (locations.html() == "") {
      info.addClass("profile-info--no-location");
    } else {
      info.removeClass("profile-info--no-location");
    }
    if (aboutText.html() == "") {
      info.addClass("profile-info--no-about");
    } else {
      info.removeClass("profile-info--no-about");
    }
  };

  $(function () {
    if ($(".js-owner-info").length) {
      ownerInfoStateDefine();
    }
  });
})();
