import $ from "jquery";

$(document).on("focus", ".js-google-places-autocomplete", function () {
  let pac = $(".pac-container"),
    arr = [];
  pac.each(function () {
    arr.push($(this));
  });
  $(this).closest(".field-input").append(arr);
});
