import $ from 'jquery';

function onceWithTimeout($element, eventName, timeout, callback) {
  const cancelPromise = new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

  const eventPromise = new Promise((resolve) => {
    $element.one(eventName, resolve);

    cancelPromise.then(() => {
      $element.off(eventName, resolve);
      resolve();
    });
  });

  eventPromise.then(callback);
}

$(document).on('click', '.btn', (e) => {
  const $el = $(e.currentTarget);

  $el.addClass('animate');

  onceWithTimeout($el, 'animationend', 1000, () => {
    $el.removeClass('animate');
  });
});
