import $ from "jquery";

var itemsToCompare = [".js-item-to-wrap:not(.js-eq-complete)"];

function elementsPerRow(items) {
  var baseOffset = $(items).eq(0).position().top;
  var breakIndex = -1;
  $(items).each(function (i) {
    if ($(this).position().top > baseOffset) {
      breakIndex = i;
      return false;
    }
  });
  var numPerRow = breakIndex === -1 ? $(items).length : breakIndex;
  return numPerRow;
}

function wrapItems(eWrap, element, first, last) {
  var item = eWrap.find(element);
  item.find(".js-eq-height").css("height", "auto");
  item.each(function () {
    var itemEq = eWrap.children(element).slice(first, last);
    itemEq.addClass("equalized");
    $(".js-eq-height-items-wrap > .equalized").wrapAll("<div></div>");
  });
}

function sortingItems() {
  var itemsWrap = $(".js-eq-height-items-wrap");
  var sliceNumb;
  var element;

  itemsWrap.each(function () {
    // sliceNumb = elementsPerRow($(this).children());
    sliceNumb = elementsPerRow($(this).children(".js-item-to-wrap"));
    element = itemsToCompare[0];
    wrapItems($(this), element, 0, sliceNumb);
  });
}

function setEqHeightInRow(eToEq) {
  $(".js-eq-height-items-wrap > *:not(.js-item-to-wrap)").each(function () {
    var itemEq = $(this).find(eToEq);
    var maxH = itemEq.eq(0).height();
    itemEq.each(function () {
      maxH = $(this).height() > maxH ? $(this).height() : maxH;
    });
    itemEq.height(maxH);
  });
  $(".js-eq-height-items-wrap .equalized")
    .removeClass("equalized")
    .addClass("js-eq-complete")
    .unwrap();
}

function startEqHieghtScript() {
  if ($(".js-eq-height-items-wrap").length) {
    sortingItems();
    setEqHeightInRow(".js-eq-height");
  }
}

$(window).on("load", function () {
  if ($(".js-item-to-wrap").length) {
    setTimeout(function () {
      startEqHieghtScript();
    }, 50);
  }
});
$(window).on("resize orientationchange", function () {
  if ($(".js-eq-complete").length) {
    $(".js-eq-complete").removeClass("js-eq-complete");
  }
  if ($(".js-item-to-wrap").length) {
    setTimeout(function () {
      startEqHieghtScript();
    }, 50);
  }
});
$(window).on("scroll", function () {
  if ($(".js-item-to-wrap").length) {
    startEqHieghtScript();
  }
});

/* simple function for  card__txt*/
function simpleEqHeight() {
  var maxH = 0;
  $(".card-membership__txt").each(function () {
    maxH = $(this).height() > maxH ? $(this).height() : maxH;
  });
  $(".card-membership__txt").height(maxH);
}

$(window).on("load", function () {
  if ($(".card-membership__txt").length) {
    simpleEqHeight();
  }
});
$(window).on("resize orientationchange", function () {
  if ($(".card-membership__txt").length) {
    simpleEqHeight();
  }
});
