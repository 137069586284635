import $ from "jquery";

function toggleRvCardStateFavorite(btn) {
  btn.closest(".rv-card").toggleClass("rv-card--favorite");
}

$(document).on("click", ".js-my-favorite-icon-btn", function () {
  toggleRvCardStateFavorite($(this));
  return false;
});
