import $ from "jquery";

(function () {
  const box = $(".js-booking-box-outer");

  if (box.length) {
    function bookingBoxPositionDefine() {
      let tLine = $(".rv-info__right-col-top-border"),
        bLine = $(".rv-info__right-col-bottom-border"),
        bLineOffset = bLine.offset().top,
        colWidth = $(".rv-info__right-col").width(),
        header = $(".top-nav__panel"),
        wS = $(window).scrollTop(),
        /* 8px - value that inherit from CSS (booking-box-outer--fixed padding-top: 6px; margin-top:-6px) - to not cut top shadow,
         */
        addTop = 40 + 8;
      if (
        wS >= tLine.offset().top - header.height() - addTop &&
        wS < bLineOffset - box.outerHeight() - header.height() - addTop
      ) {
        box
          .addClass("booking-box-outer--fixed")
          .removeClass("booking-box-outer--absolute");
        // if (window.matchMedia("(min-width: 1680px)").matches) {
        //   box.css("width", `${colWidth}px`);
        // } else {
        //   box.removeAttr("style");
        // }
      } else if (
        wS >=
        bLineOffset - box.outerHeight() - header.height() - addTop
      ) {
        box
          .removeClass("booking-box-outer--fixed")
          .addClass("booking-box-outer--absolute");
      } else {
        box
          .removeClass("booking-box-outer--fixed")
          .removeClass("booking-box-outer--absolute");
      }
    }

    $(function () {
      bookingBoxPositionDefine();
    });

    $(window).on("scroll orientationchange", function () {
      bookingBoxPositionDefine();
    });

    var resizeTimer;

    $(window).on("resize", function (e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        // Run code here, resizing has "stopped"
        bookingBoxPositionDefine();
      }, 250);
    });
  }
})();
