import $ from "jquery";

$(document).on(
  "click",
  "*[data-remove-closest], .js-remove-closest",
  function () {
    let target = $(this).data("remove-closest");
    $(this).closest(`.${target}`).remove();
  }
);
