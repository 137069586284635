import $ from "jquery";

function setShowTooltipCSS(tooltip, top, left){
  $(tooltip).css({
    opacity: '1',
    'top': `${top}px`,
    'left': `${left}px`,
  })
}
function showTooltip(event, tooltip, tooltipContainer) {
  event.stopPropagation();
  const offset = tooltipContainer.offset();
  const top = offset.top - $(tooltipContainer).height() / 2
  const left = offset.left + $(tooltipContainer).width() / 2

  const isPlacement320Left = $(tooltip).attr('data-tooltip-placement-320') !== undefined && $(tooltip).attr('data-tooltip-placement-320') === "left"
  const isPlacement320Top = $(tooltip).attr('data-tooltip-placement-320') !== undefined && $(tooltip).attr('data-tooltip-placement-320') === "top"
  const isPlacement320Bottom = $(tooltip).attr('data-tooltip-placement-320') !== undefined && $(tooltip).attr('data-tooltip-placement-320') === "bottom"
  const isPlacement768Left = $(tooltip).attr('data-tooltip-placement-768') !== undefined && $(tooltip).attr('data-tooltip-placement-768') === "left"
  const isPlacement768Top = $(tooltip).attr('data-tooltip-placement-768') !== undefined && $(tooltip).attr('data-tooltip-placement-768') === "top"

  if (window.matchMedia(`(max-width: 767.98px)`).matches && isPlacement320Left) {
    const top = offset.top + $(tooltipContainer).height() / 2
    const left = offset.left - $(tooltipContainer).width() / 2
    setShowTooltipCSS(tooltip, top, left)
  } else if (window.matchMedia(`(max-width: 767.98px)`).matches && isPlacement320Bottom) {
    const top = offset.top + $(tooltipContainer).height() / 2
    setShowTooltipCSS(tooltip, top, left)
  } else if (window.matchMedia(`(max-width: 767.98px)`).matches && isPlacement320Top) {
    const top = offset.top - $(tooltipContainer).height() / 2
    let left = offset.left - $(tooltipContainer).width() / 2

    if (left < 0) left = 16 + $(tooltipContainer).width() / 2
    else {
      left = offset.left + $(tooltipContainer).width() / 2
    }
    
    setShowTooltipCSS(tooltip, top, left)

  } else if (window.matchMedia(`(min-width: 768px)`).matches && isPlacement768Top) {
    setShowTooltipCSS(tooltip, top, left)

  } else if (window.matchMedia(`(min-width: 768px)`).matches && isPlacement768Left) {
    const top = offset.top + $(tooltipContainer).height() / 2
    const left = offset.left - $(tooltipContainer).width() / 2
    setShowTooltipCSS(tooltip, top, left)
  } else if (window.matchMedia(`(min-width: 767.98px)`).matches && isPlacement320Bottom) {
    const top = offset.top + $(tooltipContainer).height() / 2 - 4
    setShowTooltipCSS(tooltip, top, left)
  }
}

function hideTooltip(event, tooltip) {
  event.stopPropagation();
  $(tooltip).css({
    opacity: '0'
  });
}
function onMouseClick(event, tooltip, tooltipContainer) {
  event.stopPropagation();
  const opacity = $(tooltip).css('opacity')
  if (opacity === "0") {
    showTooltip(event, tooltip, tooltipContainer);
  } else {
    hideTooltip(event, tooltip);
  }
}
function setTooltipData() {
  const tooltips = $(".js-tooltip");

  tooltips.each(function (index, tooltip) {
    const tooltipContainer = $(tooltip).parent();
    if (tooltipContainer.prop("tagName") !== "BODY") {
      const triggerOnlyByClick = $(tooltip).attr('data-tooltip-triggerOnlyByClick') === 'true';
      $('body').append($(tooltip));

      if (!('ontouchstart' in document.documentElement)) {
        if (!triggerOnlyByClick) {
          $(tooltipContainer).on("mouseover", (event) => showTooltip(event, tooltip, tooltipContainer));
          $(tooltipContainer).on("mouseout", (event) => hideTooltip(event, tooltip));
        }
        $(tooltipContainer).on("click", (event) => onMouseClick(event, tooltip, tooltipContainer));
      } else {
        if (!triggerOnlyByClick) {
          $(tooltipContainer).on("touchstart", (event) => showTooltip(event, tooltip, tooltipContainer));
          $(tooltipContainer).on("touchend", (event) => hideTooltip(event, tooltip));
        } else {
          $(tooltipContainer).on("touchstart", (event) => onMouseClick(event, tooltip, tooltipContainer));
        }
      }
      $(document).on("click", (event) => hideTooltip(event, tooltip));
      $(window).on("scroll", (event) => hideTooltip(event, tooltip));
      $(window).on("resize", (event) => hideTooltip(event, tooltip));
    }
  })
}
$(function () {
  setTooltipData()
});

export { setTooltipData };
