import $ from "jquery";

function searchSleepsFieldStateDefine() {
  let field = $(".field-input--search--sleeps"),
    regex = /\d/g,
    text = field.find(".ui-selectmenu-text").text();
  if (regex.test(text) == true) {
    field.addClass("filled");
  } else {
    field.removeClass("filled");
  }
}

$(function () {
  searchSleepsFieldStateDefine();
});
