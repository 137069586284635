import $ from "jquery";

(function () {
  function mainSearchDateFieldWidth() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      let field = $(".field-input--search--date");
      field.each(function () {
        let label = $(this).find(".field-input__label"),
          labelWidth = label.width(),
          dateValue = $(this).find(".field-input__field").val(),
          spanWidth = $(this).find(".js-input-value").html(dateValue).width();

        if (window.matchMedia("(max-width: 567px)").matches) {
          spanWidth = $(this).find(".js-input-value").html(dateValue.slice(0, 5)).width();
        }
        if (!$(this).hasClass("filled")) {
          $(this).width(labelWidth);
        } else {
          $(this).width(spanWidth);
        }
      });
    } else {
      let field = $(".field-input--search--date");
      field.each(function () {
        let label = $(this).find(".field-input__label"),
          labelWidth = label.width(),
          dateValue = $(this).find(".field-input__field").val(),
          spanWidth = $(this).find(".js-input-value").html(dateValue).width();
        if (!$(this).hasClass("filled")) {
          $(this).width(labelWidth);
        } else {
          $(this).width(spanWidth);
        }
      });
    }
  }

  $(document).on(
    "change",
    ".field-input--search--date .field-input__field",
    function () {
      mainSearchDateFieldWidth();
    }
  );
  $(document).on("click", ".js-show-main-search-btn", function () {
    mainSearchDateFieldWidth();
  });

  $(function () {
    mainSearchDateFieldWidth();
  });

  var resizeTimer;

  $(window).on("scroll", function (e) {
    if (
      $(".field-input--search--date").find(".field-input__label").width() == 0
    ) {
      mainSearchDateFieldWidth();
    }
  });
  $(window).on("resize scroll orientationchange", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      // Run code here, resizing has "stopped"
      mainSearchDateFieldWidth();
    }, 150);
  });
})();
